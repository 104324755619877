$min_width_website: 1024px;
$menu_min_height: 124px; //98px
$menu_min_heightIpad: 102px;
$menu_min_heightMb: 102px;
$menu_min_height_mb: 63px;
$menu_flowaccount: 40px;

$media_menu_ipad: 1100px;
$media_menu_mobile: 480px;

/*------------------------------ fonts ------------------------------*/
$titlefont: 'Prompt', sans-serif;
$titlefont_bold: 'Prompt', sans-serif; //'Prompt-600' เปลี่ยนเป็น Prompt font-weight 500
$titlefont_light: 'Prompt-200', sans-serif;
$bodyfont: 'cs_chatthaiuiregular', sans-serif;
$iconfont: 'FontAwesome';
$contactfont: 'Montserrat', sans-serif;

$min_width_mb: 768px;
$min_width_menu: 900px;

@mixin fontstyle($family, $size, $weight, $line, $color) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  line-height: $line;
  color: $color;
}

@mixin boxshadowFunction($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin boxshadow() {
  // @include boxshadowFunction(4px 4px 21px 0 rgba(0, 0, 0, 0.11) !important);
  @include boxshadowFunction(0px 0px 0px 0 rgba(0, 0, 0, 0) !important);
}

@mixin boxshadowNone() {
  @include boxshadowFunction(none !important);
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin transitionFunction($value) {
  -webkit-transition: $value;
  -moz-transition: $value;
  -o-transition: $value;
  -ms-transition: $value;
  transition: $value;
}

@mixin title2() {
  //*
  @include fontstyle($titlefont_bold, 28px, normal, 1.39em, var(--color_gray55));

  @media (max-width: $min_width_mb) {
    font-size: 18px;
    //line-height: 2.17em;
  }

  // @media (max-width: 420px){
  //     font-size: 4.8vw;
  // }
}

@mixin body1() {
  //**
  @include fontstyle($bodyfont, 18px, normal, 1.67em, var(--color_gray64));
}

@mixin body2() {
  //**
  @include fontstyle($bodyfont, 16px, normal, 1.42, var(--color_gray64));
}

@mixin montserrat700() {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
}

// Update mixin for 2022 by nueng landing page
@mixin backgroundGray {
  background-color: #f8f9fb;
}
@mixin backgroundBlueLight {
  background-color: #e9f5fa;
}

@mixin colorGrayLight {
  color: #f8f9fb;
}
@mixin colorBlueLight {
  color: #e9f5fa;
}

@mixin boxShadowGrayLight {
  box-shadow: 0 0 20px 0 rgba(15, 2, 2, 0.08);
}

@mixin transition-time($time) {
  -webkit-transition: all $time;
  -moz-transition: all $time;
  -ms-transition: all $time;
  -o-transition: all $time;
  transition: all $time;
}

@mixin heading__title-h1 {
  color: var(--system_color_text);
  font-size: 40px;
  font-family: $titlefont;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 0;

  @media (max-width: 990px) {
    font-size: 36px;
  }

  @media (max-width: 576px) {
    font-size: 30px;
  }
}

@mixin heading__title {
  color: var(--system_color_text);
  font-size: 36px;
  font-family: $titlefont;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 0;
  // background-color: yellow;

  @media (max-width: 990px) {
    font-size: 32px;
    // background-color: red;
  }

  @media (max-width: 576px) {
    font-size: 26px;
    // background-color: green;
  }
}

@mixin heading__detail {
  font-size: 16px;
  font-family: $bodyfont;
  margin: 10px auto 20px auto;
  line-height: 1.75;
  max-width: 700px;
}

@mixin box__wrapper {
  max-width: 1170px;
  margin: auto;
  padding: 100px 15px;

  @media (max-width: 990px) {
    padding: 70px 15px;
  }

  @media (max-width: 576px) {
    padding: 50px 15px;
  }
}

@mixin link_with_background_blue {
  background-color: #e9f5fa;
  border: none;
  color: var(--app_color);
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  border-radius: 50px;
  font-family: $bodyfont;
  @include transition-time(0.2s);
  justify-content: center;
  column-gap: 5px;

  &:hover {
    background-color: #c9efff;
  }
}
