@import 'landing-global.scss';

@mixin _subheading() {
  //ใช้สำหรับ sub heading ที่เปลี่ยนสีตามแอฟ
  @include fontstyle($titlefont, 16px, 1.5em, var(--app_color));
  font-weight: 500;
}
@mixin _heading() {
  //ใช้สำหรับ heading ที่สี system_color_text
  font-family: $titlefont;
  font-weight: 500;
  color: var(--system_color_text);
}
/* เหมือนด้านบนแต่ตัวหนังสือสีขาว */
@mixin _subheading_white() {
  //ใช้สำหรับ sub heading ที่เปลี่ยนสีขาว
  @include _subheading;
  color: var(--system_color_white);
}
@mixin _heading_white() {
  //ใช้สำหรับ heading ที่สีขาว
  @include _heading;
  color: var(--system_color_white);
}

._subheading {
  @include _subheading();
}
._subheading_white {
  @include _subheading_white();
}
._heading {
  @include _heading();
}
._heading_white {
  @include _heading_white();
}

body {
  font-family: $bodyfont;
}
