$w_1: 1140px;
$w_2: 720px;
$w_3: calc(100% - 40px);

#site-map-div {
  //#1
  background: var(--app_color);
  color: var(--system_color_white);
  padding: 35px 0 50px 0;
  @media (max-width: 1000px) {
    padding: 40px 0 50px 0;
  }
  .site-map-width {
    width: 100%;
    display: flex;
    max-width: $w_1;
    margin: 0 auto;
    width: calc(100% - 40px);
    @media (max-width: 1000px) {
      flex-wrap: wrap;
      width: calc(100% - 40px);
      max-width: 720px;
    }
    .section {
      padding-right: 157px;
      @media (max-width: 1100px) {
        padding-right: 100px;
      }
      @media (max-width: 1000px) {
        width: 34%;
        padding-right: 0px;
        margin-left: unset;
      }
      @media (max-width: 590px) {
        width: 50%;
      }
      .flowpayroll.margin-pc {
        @media (max-width: 1000px) {
          display: none;
        }
      }
    }
    .section[lang="en"] {
      padding-right: 150px;
      @media (max-width: 1100px) {
        padding-right: 100px;
      }
      @media (max-width: 1000px) {
        padding-right: 50px;
      }
      @media (max-width: 520px) {
        padding-right: 0px;
      }
    }
    .section-ipad {
      display: none;
      @media (max-width: 1000px) {
        display: block;
        width: 32%;
        padding-right: 0px;
      }
      @media (max-width: 590px) {
        display: none;
      }
    }
    .section-mobile {
      display: none;
      @media (max-width: 590px) {
        display: block;
        width: 50%;
        padding-right: 0px;
      }
    }
    .section-last {
      @media (max-width: 1000px) {
        display: block;
        width: 32%;
        padding-right: 0px;
      }
      @media (max-width: 590px) {
        width: 50%;
      }
    }
  }
}

.site-map-ul {
  list-style: none;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  .sile-map-li {
    font-size: 16px;
    margin-top: 14px;
    // font-family: cs_prakasregular, sans-serif;
    line-height: 1.67em;
    font-family: 'cs_chatthaiuiregular', sans-serif;
    @media (max-width: 1000px) {
      margin-top: 10px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
    }
  }
}

//
.site-map-heading {
  font-size: 20px;
  font-family: Prompt, sans-serif;
  @media (max-width: 1000px) {
    font-size: 16px;
  }
}

.site-map-icon-heading {
  display: flex;
}

.function-payroll {
  margin-top: 50px;
}

.white-color {
  color: white;
}

.margin-pc {
  margin-top: 50px;
}
.margin-ipad {
  @media (max-width: 1000px) {
    margin-top: 60px;
  }
  @media (max-width: 590px) {
    margin-top: 40px;
  }
}
// .margin-center{
//   @media (max-width: 1000px) {
//     margin-left: 2%;
//     margin-right: 2%;
//   }
//   @media (max-width: 590px){
//     margin-left: 0%;
//     margin-right: 0%;
//   }
// }
.max-150 {
  max-width: 131px;
}
.max-150[lang="en"] {
  max-width: 138px;
}
.icon-style {
  padding-top: 5px;
  color: white;
  @media (max-width: $w_2) {
    padding-top: 1px;
  }
}
._academy_view {
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
}
._academy {
  margin-top: 86px;
  @media (max-width: 1000px) {
    display: none;
  }
}

// isOnFooter2022
.sitemap-wrapper {
  background-color: var(--app_color);
  .sitemap-desktop {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 35px 0px;
    &__column {
      .site-map__heading {
        font-size: 20px;
        font-family: Prompt, sans-serif;
        color: white;
      }
      .site-map-ul {
        list-style: none;
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        .sile-map-li {
          font-size: 16px;
          margin-top: 14px;
          line-height: 1.67em;
          font-family: 'cs_chatthaiuiregular', sans-serif;
          @media (max-width: 1000px) {
            margin-top: 10px;
          }
          @media (max-width: 375px) {
            font-size: 14px;
          }
        }
      }
    }
    .column--1,
    .column--2,
    .column--3,
    .column--5,
    .column--6,
    .column--7 {
      width: 27%;
    }
    .column--4,
    .column--8 {
      width: 19%;
    }

    .column--5,
    .column--6,
    .column--7 {
      margin-top: -40px;
    }
    .column--6 {
      .autokey {
        margin-top: 30px;
      }
    }
    .column--5,
    .column--7 {
      .find-accounting-firm:hover,
      .vat-wht-calculation:hover {
        border-bottom: 1px solid white;
        cursor: pointer;
      }
    }
  }
  .sitemap-tablet,
  .sitemap-mobile {
    display: none;
  }
  @media (max-width: 1000px) {
    .sitemap-desktop,
    .sitemap-mobile {
      display: none;
    }

    .sitemap-tablet {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 35px 0px;
      max-width: 720px;
      margin: auto;
      &__column {
        .site-map__heading {
          font-size: 18px;
          font-family: Prompt, sans-serif;
          color: white;
        }
        .site-map-ul {
          list-style: none;
          list-style-type: none;
          padding: 0px;
          margin: 0px;
          .sile-map-li {
            font-size: 16px;
            margin-top: 14px;
            line-height: 1.67em;
            font-family: 'cs_chatthaiuiregular', sans-serif;
            @media (max-width: 1000px) {
              margin-top: 10px;
            }
            @media (max-width: 375px) {
              font-size: 14px;
            }
          }
        }
      }
      .column--1,
      .column--2,
      .column--4,
      .column--5 {
        width: 36%;
      }
      .column--3,
      .column--6 {
        width: 28%;
      }

      .column--5 {
        .autokey {
          margin-top: 30px;
        }
      }

      .column--4,
      .column--5,
      .column--6 {
        margin-top: 30px;
      }

      .vat-wht-calculation {
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 590px) {
    .sitemap-desktop,
    .sitemap-tablet {
      display: none;
    }
    .sitemap-mobile {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 35px 0px;
      margin: auto;
      &__column {
        .site-map__heading {
          font-size: 16px;
          font-family: Prompt, sans-serif;
          color: white;
          @media (max-width: 320px) {
            font-size: 14x;
          }
        }
        .site-map-ul {
          list-style: none;
          list-style-type: none;
          padding: 0px;
          margin: 0px;
          .sile-map-li {
            font-size: 16px;
            margin-top: 14px;
            line-height: 1.67em;
            font-family: 'cs_chatthaiuiregular', sans-serif;
            @media (max-width: 1000px) {
              margin-top: 10px;
            }
            @media (max-width: 375px) {
              font-size: 14px;
            }
            @media (max-width: 320px) {
              font-size: 12x;
            }
          }
        }
      }
      .column--1,
      .column--2,
      .column--3,
      .column--4,
      .column--5,
      .column--6,
      .column--7,
      .column--8 {
        width: 50%;
      }

      .column--4 {
        .autokey,
        .payroll {
          margin-top: 40px;
        }
      }

      .column--5 {
        margin: 10px 0 50px 0;
      }
      .column--6 {
        margin-top: 10px;
      }
    }
  }
}
