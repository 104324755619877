@import 'landing-global.scss';

$height_big: 50px;
$min_width_big: 320px;

$height_small: 40px;
$min_width_small: 150px;

button,
button:focus {
  outline: none;
}

/* flowbtn */
a.flowbtn,
button.flowbtn {
  @include bodytext();
  height: $height_big;
  line-height: $height_big - 2px;
  padding: 0 12px;
  min-width: $min_width_big;
  text-align: center;
  @include border-radius(5px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-indent: 0px;
  text-shadow: none;
  border: 1px solid transparent;
  cursor: pointer;
  i {
    font-size: 10px;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
}
/* flowbtnsm */
a.flowbtn,
a.flowbtnsm,
button.flowbtnsm {
  font-family: $bodyfont;
  font-size: 18px;
  @include bodytext();
  height: $height_small;
  line-height: $height_small - 2px;
  padding: 0 12px;
  min-width: $min_width_small;
  text-align: center;
  @include border-radius(5px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-indent: 0px;
  text-shadow: none;
  border: 1px solid transparent;
  cursor: pointer;
  i {
    font-size: 10px;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
}
a.flowbtn,
a.flowbtnsm {
  display: inline-block;
}
a.block {
  display: block;
}

/* app-btn : ปุ่มสีแอฟ */
a.flowbtn.app-btn,
a.flowbtn.app-btn:link,
a.flowbtn.app-btn:visited,
a.flowbtnsm.app-btn,
a.flowbtnsm.app-btn:link,
a.flowbtnsm.app-btn:visited,
button.flowbtn.app-btn,
button.flowbtnsm.app-btn {
  background: var(--app_color);
  color: var(--system_color_white);
}
a.flowbtn.app-btn:hover,
a.flowbtn.app-btn:active,
a.flowbtnsm.app-btn:hover,
a.flowbtnsm.app-btn:active button.flowbtn.app-btn:hover,
button.flowbtn.app-btn:focus,
button.flowbtn.app-btn:active,
button.flowbtnsm.app-btn:hover,
button.flowbtnsm.app-btn:focus,
button.flowbtnsm.app-btn:active {
  background: var(--app_color_hover);
}

/* app-border-btn : ปุ่มที่เส้นและตัวหนังสือเป็นสีแอฟ */
a.flowbtn.app-border-btn,
a.flowbtn.app-border-btn:link,
a.flowbtn.app-border-btn:visited,
a.flowbtnsm.app-border-btn,
a.flowbtnsm.app-border-btn:link,
a.flowbtnsm.app-border-btn:visited,
button.flowbtn.app-border-btn,
button.flowbtnsm.app-border-btn {
  background: var(--system_color_white);
  color: var(--app_color);
  border: 1px solid var(--app_color);
}
a.flowbtn.app-border-btn:hover,
a.flowbtn.app-border-btn:active,
a.flowbtnsm.app-border-btn:hover,
a.flowbtnsm.app-border-btn:active button.flowbtn.app-border-btn:hover,
button.flowbtn.app-border-btn:focus,
button.flowbtn.app-border-btn:active,
button.flowbtnsm.app-border-btn:hover,
button.flowbtnsm.app-border-btn:focus,
button.flowbtnsm.app-border-btn:active {
  background: var(--system_color_white);
  color: var(--app_color_hover);
  border: 1px solid var(--app_color_hover);
}

/* app-no-border-btn : ปุ่มที่ไม่มีเส้นและตัวหนังสือเป็นสีเดียวกับ list */
a.flowbtn.app-no-border-btn,
a.flowbtn.app-no-border-btn:link,
a.flowbtn.app-no-border-btn:visited,
a.flowbtnsm.app-no-border-btn,
a.flowbtnsm.app-no-border-btn:link,
a.flowbtnsm.app-no-border-btn:visited,
button.flowbtn.app-no-border-btn,
button.flowbtnsm.app-no-border-btn {
  background: var(--system_color_white);
  color: var(--system_color_text);
  display: none;

  .icon-btn {
    height: 100%;
    margin-right: 8px;
  }

  @media (max-width: 1100px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

a.flowbtn.app-no-border-btn:hover,
a.flowbtn.app-no-border-btn:active,
a.flowbtnsm.app-no-border-btn:hover,
a.flowbtnsm.app-no-border-btn:active button.flowbtn.app-no-border-btn:hover,
button.flowbtn.app-no-border-btn:focus,
button.flowbtn.app-no-border-btn:active,
button.flowbtnsm.app-no-border-btn:hover,
button.flowbtnsm.app-no-border-btn:focus,
button.flowbtnsm.app-no-border-btn:active {
  background: var(--system_color_white);
  color: var(--app_color_hover);
}

/* app-with-icon-btn : ปุ่มที่มีไอค่อนและตัวหนังสือด้วยกัน */
a.app-with-icon-btn,
a.app-with-icon-btn:link,
a.app-with-icon-btn:visited,
a.app-with-icon-btn,
a.app-with-icon-btn:link,
a.app-with-icon-btn:visited,
button.app-with-icon-btn,
button.app-with-icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'cs_chatthaiuiregular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: var(--system_color_text);
  margin: 0px 20px;

  .icon-btn {
    height: 100%;
    margin-right: 8px;
  }

  @media (max-width: 1100px) {
    display: none;
  }
}

a.app-with-icon-btn:hover,
a.app-with-icon-btn:active,
a.app-with-icon-btn:hover,
a.app-with-icon-btn:active button.flowbtn.app-with-icon-btn:hover,
button.app-with-icon-btn:focus,
button.app-with-icon-btn:active,
button.app-with-icon-btn:hover,
button.app-with-icon-btn:focus,
button.app-with-icon-btn:active {
  color: var(--app_color_hover);
}
