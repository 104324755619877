.wordpress-banner {
  margin: 5px auto;
  -webkit-box-shadow: 0 1px 13px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 13px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 13px rgba(0, 0, 0, 0.3);
}

.sp-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.34);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
}

.sp-privacy-banner-middle {
  position: fixed;
  z-index: 9999999999999;
  padding: 0;
  color: #45413e !important;
  width: 800px;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-box-shadow: 0 1px 13px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 13px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 13px rgba(0, 0, 0, 0.3);
}

[sp-widget-holder='privacy-policy-popup'] {
  border: 10px solid #f8f8f8;
  border-radius: 10px;
}

#ifrmPrivacyBanner {
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .sp-privacy-banner-middle {
    width: 95%;
    top: 10%;
  }

  #main-privacy-banner {
    height: 80vh;
  }

  .sp-white-box .tabs-content .sp-heading-panel {
    margin-top: 0;
  }

  .sp-white-box {
    padding: 10px;
  }
}

@media screen and (max-width: 900px), screen and (max-height: 374px) {
  #main-privacy-banner {
    height: 80vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sp-privacy-banner-middle {
    width: 80%;
    top: 10%;
  }

  .sp-white-box {
    padding: 0 !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .sp-privacy-banner-middle {
    top: 10%;
  }
}

@media screen and (max-device-width: 500px) and (min-width: 319px) {
  .sp-privacy-banner-middle {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

button#closePrivacyPopup {
  cursor: pointer;
  position: absolute;
  right: -28px;
  top: -28px;
  padding: 0;
  background: #ececec;
  font-size: 2em;
  color: #474847 !important;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  line-height: 28px;
  border: solid 2px #ccc;
}

.sp-ccpa-btn,
.sp-privacy-policy-preview,
.sp-tb-embed-btn {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 12px;
  padding: 3px 10px;
}

#ifrmTrustBadge {
  position: relative;
  width: 100%;
  max-height: 65px;
}

.secure-privacy-tb-lower-left,
.secure-privacy-tb-lower-right,
.secure-privacy-tb-top-left,
.secure-privacy-tb-top-right {
  position: fixed;
  min-width: 140px;
  height: 55px;
  z-index: 9999999999999999999999;
}

.secure-privacy-tb-top-right {
  right: 20px;
  top: 10px;
}

.secure-privacy-tb-top-left {
  left: 20px;
  top: 10px;
}

.secure-privacy-tb-lower-right {
  right: 20px;
  bottom: 10px;
}

.secure-privacy-tb-lower-left {
  left: 20px;
  bottom: 10px;
}

.lower-cookie-banner,
.upper-cookie-banner {
  width: 100%;
  right: 25px !important;
  font-size: 14px;
}

.sp-banner-show {
  position: fixed;
  z-index: 9999999999999;
  padding: 0;
  border-radius: 10px;
}

.lower-cookie-banner {
  height: 310px !important;
  margin-right: 25px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15) !important;
  bottom: 100px !important;
  max-width: 270px !important;
  padding: 20px !important;
  background-color: white !important;
}

.upper-cookie-banner {
  top: 0;
}

#ifrmCookieBanner {
  height: 260px !important;
  position: relative;
  width: 100%;
  bottom: 0;
  min-width: auto;
}

.sp-ccpa-close-button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background: 0 0;
  font-size: 2.5em;
  color: #474847 !important;
  height: 25px;
  line-height: 25px;
  top: 13px;
  right: 17px;
  position: absolute;
}

.cookie-privacy-policy {
  color: #45413e;
  float: left;
  text-decoration: none;
  border-bottom: solid 1px #45413e;
  padding: 0;
}

@media screen and (max-device-width: 500px) and (min-width: 319px) {
  #sp_ccpaContainer_CCPA {
    width: 95%;
    height: 95vh;
  }
}
