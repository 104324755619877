//เดี๋ยวจัดระเบียบอีกที ว่าเอาไงเรื่อง style เพราะว่าเท่าที่ถามแก้วคือในเว็บไม่ค่อยมีการใช้ style กลางเท่าไหร่
//ต่อไป file นี้จะเป็นตัวกลางของ landing page
//ของเดิม
@import '../css/bootstrap.min.css';
@import '../css/fonts.css';
@import '../css/font-awesome.css';

//end - ของเดิม
@import 'landing-variables.scss';
@import 'landing-global.scss';
@import 'landing-button.scss';
@import 'landing-text.scss';
@import 'landing-link.scss';

//custom ของทุกอย่างเอาไว้ท้ายสุด
@import 'landing-custom.scss';
@import 'navigation.component.scss';
@import 'navigation-list-menu.scss';
@import 'navigation-list.component.scss';
@import 'navigation-hamburger.scss';
@import 'sitemap.component.scss';
