@font-face {
  font-family: 'cs_chatthaiuiregular';
  font-display: swap;
  src: url('../fonts/CSChatThaiUI/cschatthaiui-webfont.eot');
  src: url('../fonts/CSChatThaiUI/cschatthaiui-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CSChatThaiUI/cschatthaiui-webfont.woff2') format('woff2'),
    url('../fonts/CSChatThaiUI/cschatthaiui-webfont.woff') format('woff'),
    url('../fonts/CSChatThaiUI/cschatthaiui-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt-200';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url('../fonts/prompt/prompt-v1-latin_thai-200.eot');
  src: local('Prompt ExtraLight'), local('Prompt-ExtraLight'),
    url('../fonts/prompt/prompt-v1-latin_thai-200.eot?#iefix') format('embedded-opentype'),
    url('../fonts/prompt/prompt-v1-latin_thai-200.woff2') format('woff2'),
    url('../fonts/prompt/prompt-v1-latin_thai-200.woff') format('woff'),
    url('../fonts/prompt/prompt-v1-latin_thai-200.ttf') format('truetype'),
    url('../fonts/prompt/prompt-v1-latin_thai-200.svg#Prompt') format('svg');
}

@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/prompt/prompt-v1-latin_thai-regular.eot');
  src: local('Prompt'), local('Prompt-Regular'),
    url('../fonts/prompt/prompt-v1-latin_thai-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/prompt/prompt-v1-latin_thai-regular.woff2') format('woff2'),
    url('../fonts/prompt/prompt-v1-latin_thai-regular.woff') format('woff'),
    url('../fonts/prompt/prompt-v1-latin_thai-regular.ttf') format('truetype'),
    url('../fonts/prompt/prompt-v1-latin_thai-regular.svg#Prompt') format('svg');
}

@font-face {
  font-family: 'Prompt-italic';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/prompt/prompt-v1-latin_thai-italic.eot');
  src: local('Prompt Italic'), local('Prompt-Italic'),
    url('../fonts/prompt/prompt-v1-latin_thai-italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/prompt/prompt-v1-latin_thai-italic.woff2') format('woff2'),
    url('../fonts/prompt/prompt-v1-latin_thai-italic.woff') format('woff'),
    url('../fonts/prompt/prompt-v1-latin_thai-italic.ttf') format('truetype'),
    url('../fonts/prompt/prompt-v1-latin_thai-italic.svg#Prompt') format('svg');
}

/* prompt-500 - thai_latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../fonts/prompt/prompt-v5-thai_latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/prompt/prompt-v5-thai_latin-ext_latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/prompt/prompt-v5-thai_latin-ext_latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/prompt/prompt-v5-thai_latin-ext_latin-500.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/prompt/prompt-v5-thai_latin-ext_latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/prompt/prompt-v5-thai_latin-ext_latin-500.svg#Prompt') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Prompt-600';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../fonts/prompt/prompt-v1-latin_thai-600.eot');
  src: local('Prompt SemiBold'), local('Prompt-SemiBold'),
    url('../fonts/prompt/prompt-v1-latin_thai-600.eot?#iefix') format('embedded-opentype'),
    url('../fonts/prompt/prompt-v1-latin_thai-600.woff2') format('woff2'),
    url('../fonts/prompt/prompt-v1-latin_thai-600.woff') format('woff'),
    url('../fonts/prompt/prompt-v1-latin_thai-600.ttf') format('truetype'),
    url('../fonts/prompt/prompt-v1-latin_thai-600.svg#Prompt') format('svg');
}

@font-face {
  font-family: cs_prakasregular;
  font-display: swap;
  src: url(../fonts/CS_PraKas/csprakas-webfont.eot),
    url(../fonts/CS_PraKas/csprakas-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/CS_PraKas/csprakas-webfont.woff2) format('woff2'),
    url(../fonts/CS_PraKas/csprakas-webfont.woff) format('woff'),
    url(../fonts/CS_PraKas/csprakas-webfont.ttf) format('truetype'),
    url(../fonts/CS_PraKas/csprakas-webfont.svg#cs_prakasregular) format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: cs_prakasbold;
  font-display: swap;
  src: url(../fonts/CS_PraKas/csprakasbold-webfont.eot),
    url(../fonts/CS_PraKas/csprakasbold-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/CS_PraKas/csprakasbold-webfont.woff2) format('woff2'),
    url(../fonts/CS_PraKas/csprakasbold-webfont.woff) format('woff'),
    url(../fonts/CS_PraKas/csprakasbold-webfont.ttf) format('truetype'),
    url(../fonts/CS_PraKas/csprakasbold-webfont.svg#cs_prakasbold) format('svg');
  font-weight: 400;
  font-style: normal;
}

/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/montserrat-v12-latin/montserrat-v12-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url('../fonts/montserrat-v12-latin/montserrat-v12-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/montserrat-v12-latin/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../fonts/montserrat-v12-latin/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('../fonts/montserrat-v12-latin/montserrat-v12-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('../fonts/montserrat-v12-latin/montserrat-v12-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-800.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/montserrat-v12-latin/montserrat-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../fonts/montserrat-v12-latin/montserrat-v12-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/montserrat-v12-latin/montserrat-v12-latin-700.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/montserrat/Montserrat-Bold.eot'),
    url('../fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Kanit-Bold';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Kanit/Kanit-Bold.eot'), url('../fonts/Kanit/Kanit-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Kanit/Kanit-Bold.woff') format('woff'), url('../fonts/Kanit/Kanit-Bold.ttf') format('truetype');
}

.font_CSChatThaiUI {
  font-family: 'cs_chatthaiuiregular', sans-serif;
}

.font_Prompt {
  font-family: 'Prompt', sans-serif;
}

.font_PromptItalic {
  font-family: 'Prompt-italic', sans-serif;
}

.font_Prompt200 {
  font-family: 'Prompt-200', sans-serif;
}

.font_PromptBold {
  font-family: 'Prompt', sans-serif;
  font-weight: 500;
}

.font_montserrat_regular {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
}

.font_montserrat_italic {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
}

.font_montserrat_bold {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
}
.font_montserrat_700 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
}
