@import 'landing-global.scss';

@mixin fa_icon_14px() {
  position: relative;
  top: 5px;
  font-size: 14px;
}

@mixin iconStyle() {
  > i {
    @include fa_icon_14px();
    padding: 0 5px 0 0;
  }
  > i.right {
    padding: 0 0 0 5px;
  }
}

a {
  position: relative;
}

/* link-app-line */
a.link-app-line,
a.link-app-line:link,
a.link-app-line:visited {
  display: inline-block;
  color: var(--app_color);
  border-bottom: 1px solid transparent;
  @include iconStyle();
  > i,
  i.fa {
    color: var(--app_color);
  }
  > span {
    color: var(--app_color);
    border-bottom: 1px solid transparent;
  }
}
a.link-app-line:hover,
a.link-app-line:active {
  color: var(--app_color);
  text-decoration: none;
  > i,
  i.fa {
    color: var(--app_color);
  }
  > span {
    border-bottom: 1px solid var(--app_color);
  }
}

/* white-line */
a.white-line,
a.white-line:link,
a.white-line:visited {
  display: inline-block;
  color: var(--system_color_white);
  border-bottom: 1px solid transparent;
  @include iconStyle();
  > i,
  i.fa {
    color: var(--system_color_white);
  }
  > span {
    color: var(--system_color_white);
    border-bottom: 1px solid transparent;
  }
}
a.white-line:hover,
a.white-line:active {
  color: var(--system_color_white);
  text-decoration: none;
  border-bottom: 1px solid var(--system_color_white);
  > i,
  i.fa {
    color: var(--system_color_white);
  }
  > span {
    border-bottom: 1px solid var(--system_color_white);
  }
}
