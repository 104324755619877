@import './core-variables.scss';

@mixin rotate($degree) {
  -webkit-transform: rotate($degree);
  -moz-transform: rotate($degree);
  -o-transform: rotate($degree);
  transform: rotate($degree);
}

.hamburger-icon {
  width: 24px;
  height: 20px;
  position: relative;
  margin: 0 auto;
  z-index: 1004;
  @include rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  @media (max-width: 375px) {
    width: 20px;
  }
  span {
    display: block;
    position: absolute;
    height: 2px;
    height: 2.5px;
    width: 100%;
    background: var(--color_gray64);
    border-radius: 2px;
    opacity: 1;
    left: 0;
    @include rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 6px;
  }
  span:nth-child(4) {
    top: 12px;
  }
}

.hamburger-div input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  z-index: 1005;
  opacity: 0; /* hide this */
  -webkit-touch-callout: none;
}

.hamburger-div {
  input:checked ~ div.hamburger-icon {
    span:nth-child(2) {
      @include rotate(45deg);
    }
    span:nth-child(3) {
      @include rotate(-45deg);
    }
    span:nth-child(1) {
      top: 12px;
      width: 0%;
      left: 50%;
    }
    span:nth-child(4) {
      top: 12px;
      width: 0%;
      left: 50%;
    }
  }
}

.hamburger-div input:checked ~ div#flowaccount-list-menu {
  transform: none;
}
.hamburger-div input:checked ~ div#flowaccount-bg-ipad {
  @media (max-width: $media_menu_ipad) {
    display: block;
  }
  @media (max-width: $media_menu_mobile) {
    display: none;
  }
}
