//ที่เอามายังงงๆ อยู่เอามาแค่อันที่ใช้ก่อน
/*------------------------- FONTS -------------------------*/
@mixin fontstyle($family, $size, $line, $color) {
  font-family: $family;
  font-size: $size;
  line-height: $line;
  color: $color;
}
@mixin fontstyle2($family, $size, $line) {
  font-family: $family;
  font-size: $size;
  line-height: $line;
}
@mixin fontSize($size, $line) {
  font-size: $size;
  line-height: $line;
}

$titlefont: 'Prompt';
$bodyfont: 'cs_chatthaiuiregular';
$iconfont: 'FontAwesome';

@mixin bodytext() {
  //@include fontstyle($bodyfont,15px,1.53em,var(--system_color_text));
}

/*------------------------- MIXINS FUNCTION -------------------------*/
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

//ปิดสัมมนาฟรีทั้งเว็บ - ถ้าวันไหนเปิดใช่ลบอันนี้ออกเลย
.freeseat {
  display: none !important;
}
//end - ปิดสัมมนาฟรีทั้งเว็บ - ถ้าวันไหนเปิดใช่ลบอันนี้ออกเลย
