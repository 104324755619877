@import './core-variables.scss';
/*
    a.flow-menu ความสูง //h
    pc : 40 
    ipad : 63
    mb : 50
    $menu_flowaccount : 40
*/

#flow-menu-div {
  margin-left: 10px;

  hr {
    display: none;
  }

  .listmenu,
  .listmenu:before,
  .listmenu:after {
    -webkit-user-select: none;
    /* Chrome/Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+ */
  }

  .listmenu {
    input[type='radio'] {
      display: block;
      width: 100%;
      height: 32px;
      position: absolute;
      top: 0px;
      left: 0px;
      cursor: pointer;
      z-index: 1005;
      opacity: 0;

      @media (max-width: $media_menu_ipad) {
        height: 46px;
      }

      @media (max-width: $media_menu_mobile) {
        height: 46px;
      }
    }

    input[type='radio']:hover ~ a.flow-menu {
      color: var(--app_color);
    }

    input[type='radio']:checked ~ div.flow-submenu {
      opacity: 1;

      .shadow-box {
        display: block;
      }

      @media (max-width: $media_menu_ipad) {
        // top: 0px;
      }
    }

    input[type='radio']:checked ~ a.flow-menu {
      .more-icon .fa-angle-down:before {
        content: '\f106';
      }
    }

    margin: 0 12px;
    display: inline-block;
    position: relative;

    // //for_test
    // background: yellowgreen;
    // //for_test
    a.flow-menu {
      @include fontstyle($bodyfont, 16px, normal, $menu_flowaccount, var(--system_color_text));
    }

    a.flow-menu,
    a.flow-menu:link,
    a.flow-menu:visited {
      display: block;

      .more-icon {
        display: inline-block;

        i.fa-angle-up,
        i.fa-angle-down {
          position: relative;
          top: 2px;
        }

        i.fa-angle-up {
          display: none;
        }

        i.fa-angle-down {
          display: inline-block;
        }
      }

      .more-icon.up {
        i.fa-angle-up {
          display: inline-block;
        }

        i.fa-angle-down {
          display: none;
        }
      }
    }

    a.flow-menu:hover,
    a.flow-menu:active {
      color: var(--app_color);
    }

    a.flow-menu.current {
      color: var(--app_color) !important;
    }
  }

  @media (max-width: 1250px) {
    //สำหรับย่อจอเมนู
    .listmenu {
      margin: 0 7.5px;
      margin: 0 0.75vw;
      // background: yellow; //for test
    }
  }

  @media (max-width: 1050px) {
    //สำหรับย่อจอเมนู
    .listmenu {
      margin: 0 5px;
      margin: 0 0.3vw;
      //background: orange; //for test
    }
  }

  @media (max-width: $media_menu_ipad) {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;

    hr {
      display: block;
      margin: 0 25px; //padding_menu_ipad
      border-top: 1px solid #ebebeb;
    }

    .listmenu {
      margin: 0;
      display: block;

      a.flow-menu {
        padding: 11.5px 25px; //padding_menu_ipad //h
        font-size: 18px;

        .more-icon {
          float: right;
        }
      }

      // a.flow-menu::after {
      //   display: block;
      //   clear: both;
      //   content: '';
      // }
    }

    .listmenu.open-listmenu:not(._function) {
      hr {
        display: none;
      }
    }
  }

  @media (max-width: $media_menu_mobile) {
    hr {
      margin: 0 20px; //padding_menu_ipad
    }

    .listmenu {
      a.flow-menu {
        padding: 5px 20px; //padding_menu_mb //h
      }
    }
  }
}

/* flow-submenu */
/* left คิดจาก ความอ้วนของเมนูนั้น / 2 - 195 */
$submenu_w: 390px;

._product {
  .flow-submenu {
    left: -151.5px;
  }

  .flow-submenu[lang="en"] {
    left: -151.5px;
  }
}

._function {
  .flow-submenu {
    left: -160px;
  }

  .flow-submenu[lang="en"] {
    left: -151.5px;
  }
}

._partner {
  .flow-submenu {
    left: -160px;
  }

  .flow-submenu[lang="en"] {
    left: -151.5px;
  }
}

._academy {
  .flow-submenu {
    left: -154px;
  }

  .flow-submenu[lang="en"] {
    left: -149.5px;
  }
}

._about-us {
  .flow-submenu {
    left: -154px;
  }

  .flow-submenu[lang="en"] {
    left: -149.5px;
  }
}

._more {
  .flow-submenu {
    left: -158.5px;
  }

  .flow-submenu[lang="en"] {
    left: -166px;
  }

  .classic-style {
    image-component {
      width: 20px;
      margin-right: 10px;
      display: inline-block;
    }

    margin-top: 15px !important;

    i {
      width: 30px !important;
    }

    @media (max-width: $media_menu_ipad) {
      margin-top: 0px !important;
    }
  }
}

.flow-submenu {
  // display: none; //ปิดเมนูสั่งหรับตอนแรก
  opacity: 0;
  position: absolute;
  top: 40px;
  width: $submenu_w;
  transition: opacity 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

  .arrow {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0;
    bottom: 0;
    top: 12px;
    left: calc(50% + 12px);
    box-sizing: border-box;
    border: 9px solid #000;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    @include boxshadowFunction(-3px 3px 3px 0 rgba(0, 0, 0, 0.02));
  }

  .shadow-box {
    display: none;
    margin-top: 12px;
    background-color: #fff;
    padding: 15px;
    @include boxshadowFunction(0 0 30px 0 rgba(0, 0, 0, 0.16));
    @include border-radius(5px);
  }

  a.submenu,
  a.submenu:link,
  a.submenu:visited {
    display: block;
    font-family: $titlefont;
    font-weight: 500;
    font-size: 16px;
    line-height: 50px;
    padding: 0 10px;
    color: var(--system_color_text);
    @include border-radius(5px);

    i {
      width: 30px;
      color: var(--app_color);
      line-height: 50px;
    }
  }

  a.submenu:hover,
  a.submenu:active {
    background: #f5f7fa;
  }

  .star-icon-mobile {
    margin-right: 5px;
    top: 1px;
    color: #fea000 !important;
  }

  a.submenu.detail {
    padding-top: 13px;
    margin-bottom: 15px;

    .logo {
      float: left;
      width: 20px;
      margin-right: 10px;
      margin-top: 3px;
      line-height: 1;

      i {
        vertical-align: top;
        line-height: 1;
        margin-top: 3px;
      }
    }

    .long-version-product {
      padding-left: 10px;
    }

    .submenu-detail {
      float: left;
      width: calc(100% - 30px);

      h2 {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 4px;

        .tag {
          background: #06b189;
          width: 36px;
          font-size: 10px;
          line-height: 16px;
          text-align: center;
          display: inline-block;
          margin-left: 8px;
          @include border-radius(3px);
          position: relative;
          top: -3px;
        }
      }

      h3 {
        font-family: $bodyfont;
        font-size: 14px;
        line-height: 1.6;
        color: #4e545e;
        padding-bottom: 5px;
      }
    }
  }

  a.submenu.detail.no-description {
    padding-bottom: 5px;
  }

  a.submenu.detail.no-description.custom-margin-bottom {
    margin-bottom: 10px;
  }

  a.submenu:last-child {
    margin-bottom: 0;
  }

  a.submenu.detail-last {
    background: #f5f7fa;
    width: calc(100% + 30px);
    margin: 0 -15px;
    padding: 0 0 0 25px;
    @include border-radius(5px);
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    i {
      width: 25px;
      font-size: 16px;
      color: var(--app_color);
    }

    i.orange {
      color: #fea000;
    }
  }

  @media (max-width: $media_menu_ipad) {
    position: relative;
    // transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    top: 0;
    left: 0 !important;
    width: 100%;
    padding: 0 25px; //padding_menu_ipad
    margin-top: -11.5px;

    .arrow {
      display: none;
    }

    .shadow-box {
      margin-top: 0px;
      padding: 0px;
      @include boxshadowFunction(none);
      @include border-radius(0);
    }

    a.submenu {
      background: #fff !important;
      padding: 0px;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    a.submenu.detail {
      margin: -7px 0 10px 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    a.submenu.detail:first-child {
      margin-top: 0;
    }

    a.submenu.detail-last {
      background: #f5f7fa !important;
      width: calc(100% + 50px);
      margin: 0 -25px;
      padding: 0 0 0 25px !important;
    }
  }

  @media (max-width: $media_menu_mobile) {
    padding: 0 20px; //padding_menu_mb
    margin-top: -5px;

    a.submenu.detail-last {
      width: calc(100% + 40px);
      margin: 0 -20px;
      padding: 0 0 0 20px !important;
    }
  }
}

.flow-submenu.have-detail-last {
  .shadow-box {
    padding-bottom: 0;
  }

  .submenu.detail-last.classic-style {
    picture {
      img {
        width: 20px;
      }
    }
  }
}

/* end - flow-submenu */

// style navigaiton-list with hover dropdown for desktop
.navigation-wrapper {
  display: flex;

  .nav-bar {
    padding-bottom: 10px;
    list-style: none;
    padding-inline-start: 20px;
    
    @media (max-width: 1130px) {
      padding-inline-start: 15px;
    }

    &__list {
      // padding: 0px 10px 15px;
      height: 38px;
      // background-color: rgb(165, 165, 160);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover ul,
      &:focus ul {
        opacity: 1;
        visibility: visible;
      }

      &:hover {
        .nav-title {
          color: var(--app_color);
        }

        .fa-angle-up {
          display: block;
          color: var(--app_color);
        }

        .fa-angle-down {
          display: none;
        }
      }

      &:not(:hover) {
        .fa-angle-up {
          display: none;
        }

        .fa-angle-down {
          display: block;
        }
      }

      .nav-title {
        display: flex;
        column-gap: 5px;
        align-items: center;

        @include fontstyle($bodyfont, 16px, normal, $menu_flowaccount, var(--system_color_text));

        .fa-angle-up,
        .fa-angle-down {
          padding-top: 2px;
        }
      }

      &-dropdown {
        visibility: hidden;
        opacity: 0;
        list-style: none;

        background-color: #fff;
        padding: 15px 15px 0 15px;
        box-shadow: 8px 2px 32px 0 rgba(0, 0, 0, 0.11);
        border-radius: 5px;

        width: 390px;
        transition: opacity 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);

        position: absolute;
        top: 100px;
        &.function-menu{
          width: 440px;
        }

        .arrow-sign {
          -webkit-clip-path: polygon(50% 0, 0 44%, 100% 44%);
          clip-path: polygon(50% 0, 0 44%, 100% 44%);
          background-color: white;
          height: 30px;
          width: 27px;
          position: absolute;
          top: -11px;
          left: calc(50% + -13px);
        }

        li {
          margin-bottom: 10px;

          &:hover {
            background: #f5f7fa;
            border-radius: 5px;
          }
        }

        li.last-child-gray {
          padding: 0 15px;
          margin: 0 -15px;
          background: #f5f7fa;

          .fa-arrow-circle-up {
            color: #fea000;
            padding-top: 2px;
          }

          .fa-code {
            color: var(--app_color);
            padding-top: 2px;
          }

          h2 {
            margin-top: 0px;
            margin-bottom: 0;
            padding-top: 3px;
          }

          .product-wrapper {
            padding: 15px 10px;
          }
        }

        .product-wrapper {
          display: flex;
          column-gap: 10px;
          padding: 10px;

          h2 {
            font-size: 16px;
            margin-top: 5px;
          }

          h3 {
            @include fontstyle($bodyfont, 14px, normal, $menu_flowaccount, var(--system_color_text));
            line-height: 1.6;
          }

          .text--shopee-lazada {
            margin-left: -2px;
          }
          .logo > .fa-star {
            position: relative;
            top: -1px;
            padding-left: 5px;
            font-size: 17px;
            color: #fea000;
          }
          .long-version-product {
            padding-left: 20px;
          }
        }

        .product-wrapper.detail-none {
          h2 {
            margin-bottom: 1px;
          }
        }
      }
    }
  }

  #fucntion-tab,
  #acamedy-tab,
  #find-accountant-tab {
    li:not(.last-child-gray) {
      .product-wrapper {
        i {
          color: var(--app_color);
        }
      }
    }
  }

  #fucntion-tab {
    li:not(.last-child-gray) {
      .product-wrapper {
        column-gap: 18px;
      }
    }

    li.last-child-gray {
      .product-wrapper {
        column-gap: 10px;
      }
    }
  }

  #acamedy-tab,
  #find-accountant-tab {
    li:not(.last-child-gray) {
      .product-wrapper {
        i {
          padding-top: 5px;
        }
      }
    }

    li.last-child-gray {
      .product-wrapper {
        i {
          padding-top: 0px;
        }
      }
    }
  }

  #become-partner-tab {
    li {
      .product-wrapper {
        i {
          padding-top: 4px;
          color: var(--app_color);
        }
      }
    }
  }

  #price-tab,
  #become-partner-tab {
    .nav-title {
      a {
        color: var(--system_color_text);
      }

      a.current {
        color: var(--app_color);
      }
    }

    a:hover {
      color: var(--app_color);
    }
  }
}

@media (min-width: 1101px) {
  .navigation-wrapper {
    display: inline-flex;
  }

  #flow-menu-div.flowaccount-landing {
    display: none;
  }
}

@media (max-width: 1100px) {
  .navigation-wrapper {
    display: none;
  }

  #flow-menu-div.flowaccount-landing {
    display: block;
  }
}
