@import 'core-variables.scss';

@mixin heading2() {
  //*
  @include fontstyle($titlefont_bold, 42px, normal, 1.29em, var(--app_color));
  font-weight: 500;

  @media (max-width: $min_width_mb) {
    font-size: 20px;
    line-height: 1.5em;
  }

  @media (max-width: 420px) {
    font-size: 5.3vw;
  }
}

@mixin detailContact() {
  //*
  @include fontstyle($titlefont, 26px, normal, 1.5em, var(--system_color_white));

  @media (max-width: $min_width_mb) {
    font-size: 18px;
    line-height: 1.5em;
  }

  @media (max-width: 420px) {
    font-size: 4.5vw;
  }
}

@mixin headingContact() {
  //*
  @include fontstyle($titlefont_bold, 42px, normal, 1.29em, var(--system_color_white));
  font-weight: 500;

  @media (max-width: $min_width_mb) {
    font-size: 20px;
    line-height: 1.5em;
  }

  @media (max-width: 420px) {
    font-size: 5.3vw;
  }
}

@mixin heading2_en() {
  //*
  @include fontstyle($titlefont_bold, 32px, normal, 1.29em, var(--app_color));
  font-weight: 500;

  @media (max-width: $min_width_mb) {
    font-size: 20px;
    line-height: 1.5em;
  }

  @media (max-width: 420px) {
    font-size: 5.3vw;
  }
}

@mixin headingQ() {
  //*
  @include fontstyle($titlefont_bold, 42px, normal, 1.29em, var(--color_gray55));
  font-weight: 500;

  @media (max-width: $min_width_mb) {
    font-size: 20px;
    line-height: 1.5em;
  }

  @media (max-width: 420px) {
    font-size: 5.3vw;
  }
}

@mixin titleClick() {
  //*
  @include fontstyle($titlefont_bold, 2vw, normal, 0.94em, var(--color_gray55));
  font-weight: 500;

  @media (max-width: $min_width_mb) {
    font-size: 19px;
    line-height: normal;
    color: var(--color_gray46);
  }

  // @media (max-width: 420px){
  //     font-size: 7vw;
  // }
}

.h4,
h4 {
  font-size: 18px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

body.modal-open {
  padding-right: 0 !important;
  overflow: visible;

  .modal.fade.show {
    padding-right: 0 !important;
  }
}

.font_Icomoon {
  font-family: icomoon;
}

.font_Rsu {
  font-family: rsu-regular, sans-serif;
}

.font_Prakas {
  font-family: cs_prakasregular, sans-serif;
}

.font_PrakasBold {
  font-family: cs_prakasbold, sans-serif;
}

.font_Prompt {
  font-family: Prompt, sans-serif;
}

.font_PromptItalic {
  font-family: Prompt-italic, sans-serif;
}

.font_Prompt200 {
  font-family: Prompt-200, sans-serif;
}

.font_PromptBold {
  font-family: 'Prompt', sans-serif;
  font-weight: 500;
}

.font_ChatthaiUi {
  font-family: cs_chatthaiuiregular, sans-serif;
}

.prakas {
  font-family: cs_prakasregular, sans-serif;
}

.prakasBold {
  font-family: cs_prakasbold, sans-serif;
}

.prompt {
  font-family: Prompt, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.promptBold {
  font-family: Prompt, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.montserratBold {
  font-family: Montserrat-Bold, sans-serif;
}

.kanitBold {
  font-family: Kanit-Bold, sans-serif;
}

.kanitMedium {
  font-family: Kanit-Medium, sans-serif;
}

.kanitSemiBold {
  font-family: Kanit-SemiBold, sans-serif;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear:after {
  clear: both;
}

.heading1,
h1.heading1 {
  font-family: Prompt, sans-serif;
  font-size: 42px;
  line-height: 1.3em;
  color: #fff;
}

.heading2 {
  font-family: Prompt, sans-serif;
  font-size: 30px;
  line-height: 44px;
  color: #38abe0;
}

.titletext {
  font-family: cs_prakasregular, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: #4e545e;
}

.bodytext {
  font-family: cs_prakasregular, sans-serif;
  font-size: 16px;
  line-height: 23px;
  color: #4e545e;
}

.captiontext {
  font-family: cs_prakasregular, sans-serif;
  font-size: 14px;
  line-height: 23px;
  color: #4e545e;
}

.owl-dots .owl-dot {
  display: inline-block;
}

.owl-dots .owl-dot span {
  background: #4fc2f8;
  border-radius: 20px;
  display: block;
  height: 12px;
  margin: 5px 7px;
  opacity: 0.5;
  width: 12px;
}

.owl-dots .owl-dot.active span {
  background: #2898cb;
}

.black {
  color: #000;
}

.white {
  color: #fff;
}

.blue28 {
  color: #2898cb;
}

.blue38 {
  color: #38abe0;
}

.blue74 {
  color: #74c3e6;
}

.gray33 {
  color: #333;
}

.gray4E {
  color: #4e545e;
}

.gray66 {
  color: #666;
}

.gray99 {
  color: #999;
}

.yellowF8 {
  color: #f8e71c;
}

.btnGreen15,
.btnGreen15:link,
.btnGreen15:visited {
  font-family: cs_prakasregular, sans-serif;
  font-size: 20px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  background: #66bd00;
  color: #fff;
  border: 1px solid #65a400;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  min-width: 300px;
}

.btnGreen15:link,
.btnGreen15:visited,
.en .btnGreen15 {
  font-size: 20px;
  line-height: 20px;
  padding: 10.5px 0 9.5px 0;
}

.btnGreen15:active,
.btnGreen15:hover {
  background: #21a624;
  border: 1px solid #21a624;
}

.btnBlue15,
.btnBlue15:link,
.btnBlue15:visited {
  font-family: cs_prakasregular, sans-serif;
  font-size: 16px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  background: #fff;
  color: #2898cb;
  border: 1px solid #2898cb;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

.btnBlue15:active,
.btnBlue15:hover {
  background: #2898cb;
  color: #fff;
}

.unset-margin {
  margin-top: unset;
  margin-bottom: unset;
}

.tran5 {
  -webkit-transition: all 5s ease-in;
  -moz-transition: all 5s ease-in;
  -o-transition: all 5s ease-in;
  -ms-transition: all 5s ease-in;
  transition: all 5s ease-in;
}

.relative {
  position: relative;
}

.clicksnow {
  position: relative;
  z-index: 2;
}

.modal-content {
  background: #fff !important;
}

@media (max-width: 767px) {
  .heading1,
  h1.heading1 {
    font-size: 36px;
    line-height: 1.3em;
  }

  .heading2 {
    font-size: 28px;
    line-height: 1.3em;
  }

  .titletext {
    font-size: 20px;
    line-height: 32px;
  }

  .bodytext {
    font-size: 16px;
    line-height: 23px;
  }

  .captiontext {
    font-size: 14px;
    line-height: 23px;
  }

  .btnGreen15,
  .btnGreen15:link,
  .btnGreen15:visited {
    min-width: 200px;
  }
}

@media (max-width: 510px) {
  .heading1,
  h1.heading1 {
    font-size: 30px;
    font-size: 6.5vw;
    line-height: 1.3em;
  }

  .heading2 {
    font-size: 26px;
    font-size: 5.2vw;
    line-height: 1.3em;
  }

  .titletext {
    font-size: 16px;
    line-height: 1.3em;
  }
}

@media (max-width: 420px) {
  .padding-seminar {
    max-width: 320px;
    margin: auto;
    padding: 15px 0;
  }
}

body {
  position: relative;
  min-width: 376px;
  height: 100%;
  padding: 0;
  margin: 0;
  @include body2();
}

/* header */
header.advance-flow-navigation,
header.flowaccount-navigation {
  background-color: var(--system_color_white);
  z-index: 1040 !important;
}

.flowaccount-navigation {
  .custom-navbar-btn {
    font-size: 16px;
    min-width: 140px;
  }
}

/* container */
.advance-flow-container {
  margin-top: $menu_min_height;

  // ipad size
  @media (max-width: $media_menu_ipad) {
    margin-top: $menu_min_heightIpad;
  }

  // mobile size
  @media (max-width: $media_menu_mobile) {
    margin-top: $menu_min_heightMb;
  }

  .wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1110px;
  }
}
@media (max-width: $media_menu_mobile) {
  .advance-flow-container.safari {
    margin-top: 30px;
  }
}

.wrapper2 {
  width: 100%;
  margin: 0 auto;
  // max-width: 1140px;
}

/* footer */
/*--------- share style ----------*/
.heading-page {
  border-top: 12px solid var(--app_color);
  padding-top: 25px;
  margin: 108px 0 100px 0;
  @include heading2();
  display: inline-block;

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 36px;
    margin-bottom: 80px;
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    font-size: 36px;
    margin-bottom: 80px;
  }

  @media (max-width: $min_width_mb) {
    border-top: 8px solid var(--app_color);
    padding-top: 3%;
    margin-top: 15%;
    margin-bottom: 12%;
  }
}

.heading-page.center-div {
  text-align: center;
  margin: 108px auto 100px auto;
  display: table;

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 36px;
    margin-bottom: 80px;
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    font-size: 36px;
    margin-bottom: 80px;
  }

  @media (max-width: $min_width_mb) {
    border-top: 8px solid var(--app_color);
    padding-top: 3%;
    margin-top: 15%;
    margin-bottom: 12%;
  }
}

.detailContact {
  @include detailContact();
  padding: 0 15px 0 15px;
}

//Contact sales
.heading-page2 {
  border-top: 12px solid var(--system_color_white);
  padding-top: 25px;
  margin: 50px 0 50px 0;
  @include headingContact();
  display: inline-block;

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 36px;
    margin-bottom: 80px;
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    font-size: 36px;
    margin-bottom: 80px;
  }

  @media (max-width: $min_width_mb) {
    border-top: 8px solid var(--system_color_white);
    padding-top: 3%;
    margin-top: 15%;
    margin-bottom: 12%;
  }
}

.heading-page2.center-div {
  text-align: center;
  margin: 50px auto 25px auto;
  display: table;
  padding: 10px;

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 36px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin: 50px auto 25px auto;
  }

  // for safari
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    font-size: 36px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin: 50px auto 25px auto;
  }

  @media (max-width: 420px) {
    border-top: 8px solid var(--system_color_white);
    padding-top: 3%;
    margin-top: 15%;
    margin-bottom: 12%;
  }
}

//
.text-head {
  @media (max-width: 420px) {
    text-align: center;
  }

  .heading2 {
    border-top: 12px solid var(--app_color);
    padding-top: 25px;
    margin: 108px 0 50px 0;
    @include heading2();
    display: inline-block;

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
      .heading2 {
        font-size: 36px;
        margin: 108px 0 80px 0;
      }
    }

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
      .heading2 {
        font-size: 36px;
        margin: 108px 0 80px 0;
      }
    }

    @media (max-width: $min_width_mb) {
      .heading2 {
        border-top: 8px solid var(--app_color);
        padding-top: 3%;
        margin: 15% 0 12% 0;
      }
    }
  }

  .heading2.en {
    border-top: 12px solid var(--app_color);
    padding-top: 25px;
    margin: 108px 0 50px 0;
    @include heading2_en();
    display: inline-block;

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
      .heading2 {
        font-size: 36px;
        margin: 108px 0 80px 0;
      }
    }

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
      .heading2 {
        font-size: 36px;
        margin: 108px 0 80px 0;
      }
    }

    @media (max-width: $min_width_mb) {
      .heading2 {
        border-top: 8px solid var(--app_color);
        padding-top: 3%;
        margin: 15% 0 12% 0;
      }
    }
  }

  .headingQ {
    border-top: 12px solid var(--color_gray55);
    padding-top: 25px;
    margin: 108px 0 50px 0;
    @include headingQ();
    display: inline-block;
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .headingQ {
      font-size: 5.3;
      margin: 108px 0 80px 0;
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    .headingQ {
      font-size: 5.3;
      margin: 108px 0 80px 0;
    }
  }

  @media (max-width: $min_width_mb) {
    .headingQ {
      border-top: 8px solid var(--color_gray55);
      padding-top: 3%;
      margin: 10% 0 6% 0;
    }
  }
}

//
.modal-backdrop {
  background-color: #fff !important;
}

.backdrop {
  background-color: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
}

.modal-dialog {
  margin: 15vh auto 0 auto;

  .modal-content {
    border-radius: 5px;
    box-shadow: 0 5px 7px 0 rgba(204, 208, 217, 0.5);
    background-color: #ffffff;
    border: solid 1px #ccd0d9;
    padding: 0 20px;

    .modal-header {
      border-bottom: none;
      padding-top: 30px;

      // padding-bottom: 5px;
      .modal-title {
        @include title2();
        color: var(--app_color) !important;
        text-align: center;
        width: 100%;
      }

      .close {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
        padding: 0;
        font-size: 36px;
        line-height: 40px;
        height: 40px;
        width: 40px;
        font-family: 'cs_chatthaiuiregular', sans-serif;
        border: none;
        outline: none;

        span {
          display: block;
        }
      }
    }

    .modal-body {
      padding-top: 5px;
      padding-bottom: 25px;
      text-align: center;
      font-family: 'cs_chatthaiuiregular', sans-serif;
      font-size: 15px;
      line-height: 1.5em;
    }

    .modal-footer {
      padding: 10px 0;

      .btn {
        padding: 0px 15px;
        min-width: 130px;
      }
    }
  }
}

.btn-primary {
  background: var(--app_color);
  border-color: var(--app_color_hover);
}

.btn-primary:hover {
  background: var(--app_color);
  border-color: var(--app_color_hover);
}

//
.select-function {
  .tablediv {
    display: table;
    width: 100%;

    .tablecell {
      display: table-cell;
      width: 50%;
      padding: 60px 2%;

      a {
        img {
          width: 50%;
          max-width: 240px;
          margin: 0 auto 5% auto;
        }

        span {
          @include titleClick();
          line-height: 1.53em;
          color: var(--app_color) !important;
        }
      }
    }

    .tablecell:hover {
      background-color: #f7fdff;
    }

    .tablecell.bg-blue {
      background-color: #f7fdff;
    }

    @media (max-width: 900px) {
      .tablecell {
        padding: 5% 2%;

        a {
          span {
            font-size: 4vw;
          }
        }
      }
    }

    @media (max-width: 420px) {
      .tablecell {
        display: block;
        width: 100%;

        a {
          span {
            font-size: 19px;
          }
        }
      }
    }
  }
}

@font-face {
  font-family: 'icomoon';
  src: url(../fonts/icomoon/icomoon.eot?9cj3xm);
  src: url('../fonts/icomoon/icomoon.eot?9cj3xm#iefix') format('embedded-opentype'),
    url(../fonts/icomoon/icomoon.ttf?9cj3xm) format('truetype'),
    url(../fonts/icomoon/icomoon.woff?9cj3xm) format('woff'),
    url('../fonts/icomoon/icomoon.svg?9cj3xm#icomoon') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: rsu-regular, sans-serif;
  src: url(../fonts/RSU/rsu_regular-webfont.eot);
  src: url('../fonts/RSU/rsu_regular-webfont.eot?#iefix') format('embedded-opentype'),
    url(../fonts/RSU/rsu_regular-webfont.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

[class^='fl-'],
[class*=' fl-'] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fl-Download_AppStore .path1:before {
  content: '\e950';
  color: rgb(166, 166, 166);
}

.fl-Download_AppStore .path2:before {
  content: '\e951';
  margin-left: -3.375em;
  color: rgb(0, 0, 0);
}

.fl-Download_AppStore .path3:before {
  content: '\e952';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path4:before {
  content: '\e953';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path5:before {
  content: '\e954';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path6:before {
  content: '\e955';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path7:before {
  content: '\e956';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path8:before {
  content: '\e957';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path9:before {
  content: '\e958';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path10:before {
  content: '\e959';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path11:before {
  content: '\e95a';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path12:before {
  content: '\e95b';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path13:before {
  content: '\e95c';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path14:before {
  content: '\e95d';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path15:before {
  content: '\e95e';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path16:before {
  content: '\e95f';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path17:before {
  content: '\e960';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path18:before {
  content: '\e961';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path19:before {
  content: '\e962';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path20:before {
  content: '\e963';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path21:before {
  content: '\e964';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path22:before {
  content: '\e965';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path23:before {
  content: '\e966';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path24:before {
  content: '\e967';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-Download_AppStore .path25:before {
  content: '\e968';
  margin-left: -3.375em;
  color: rgb(255, 255, 255);
}

.fl-intercom:before {
  content: '\e94a';
}

.fl-radio-check-c .path1:before {
  content: '\e94b';
  color: rgb(255, 255, 255);
}

.fl-radio-check-c .path2:before {
  content: '\e94c';
  margin-left: -1.0322265625em;
  color: rgb(153, 153, 153);
}

.fl-radio-check-c .path3:before {
  content: '\e94d';
  margin-left: -1.0322265625em;
  color: rgb(40, 152, 203);
}

.fl-radio-uncheck-c .path1:before {
  content: '\e94e';
  color: rgb(255, 255, 255);
}

.fl-radio-uncheck-c .path2:before {
  content: '\e94f';
  margin-left: -1.0322265625em;
  color: rgb(153, 153, 153);
}

.fl-checked-dash .path1:before {
  content: '\e947';
  color: rgb(255, 255, 255);
}

.fl-checked-dash .path2:before {
  content: '\e948';
  margin-left: -1.0322265625em;
  color: rgb(153, 153, 153);
}

.fl-checked-dash .path3:before {
  content: '\e949';
  margin-left: -1.0322265625em;
  color: rgb(40, 152, 203);
}

.fl-checked-color .path1:before {
  content: '\e942';
  color: rgb(255, 255, 255);
}

.fl-checked-color .path2:before {
  content: '\e943';
  margin-left: -1.0322265625em;
  color: rgb(153, 153, 153);
}

.fl-checked-color .path3:before {
  content: '\e944';
  margin-left: -1.0322265625em;
  color: rgb(40, 152, 203);
}

.fl-uncheck-color .path1:before {
  content: '\e945';
  color: rgb(255, 255, 255);
}

.fl-uncheck-color .path2:before {
  content: '\e946';
  margin-left: -1.0322265625em;
  color: rgb(153, 153, 153);
}

.fl-receipt:before {
  content: '\e940';
}

.fl-picture:before {
  content: '\e941';
}

.fl-adduser_officeadmin:before {
  content: '\e93f';
}

.fl-play-circle:before {
  content: '\e93e';
}

.fl-user:before {
  content: '\e93d';
}

.fl-job-adaptseasily:before {
  content: '\e93a';
}

.fl-job-creative:before {
  content: '\e93b';
}

.fl-job-workwithother:before {
  content: '\e93c';
}

.fl-doc-flowaccount:before {
  content: '\e939';
}

.fl-Social-line:before {
  content: '\e938';
}

.fl-income_graphs:before {
  content: '\e936';
}

.fl-expense_graghs:before {
  content: '\e937';
}

.fl-playvdo:before {
  content: '\e934';
}

.fl-downloaddocument:before {
  content: '\e935';
}

.fl-circlecorrect:before {
  content: '\e933';
}

.fl-link:before {
  content: '\e932';
}

.fl-money:before {
  content: '\e930';
}

.fl-creditcard:before {
  content: '\e931';
}

.fl-menu_finance:before {
  content: '\e92f';
}

.fl-matching_left:before {
  content: '\e92c';
}

.fl-matching_right:before {
  content: '\e92d';
}

.fl-search:before {
  content: '\e92e';
}

.fl-radiocheck:before {
  content: '\e92a';
}

.fl-radioforcheck:before {
  content: '\e92b';
}

.fl-contact:before {
  content: '\e903';
}

.fl-product:before {
  content: '\e904';
}

.fl-withholdingtax:before {
  content: '\e927';
}

.fl-correct:before {
  content: '\e928';
}

.fl-warning:before {
  content: '\e929';
}

.fl-adduser_accountant:before {
  content: '\e915';
}

.fl-adduser_owner:before {
  content: '\e916';
}

.fl-adduser_sales_admin:before {
  content: '\e917';
}

.fl-adduser_sales_manager:before {
  content: '\e918';
}

.fl-adduser_senior_sales:before {
  content: '\e919';
}

.fl-edit_pencil:before {
  content: '\e91a';
}

.fl-setting_company_user:before {
  content: '\e91b';
}

.fl-setting_company:before {
  content: '\e91c';
}

.fl-setting_default_email:before {
  content: '\e91d';
}

.fl-setting_document_template:before {
  content: '\e91e';
}

.fl-setting_document_title:before {
  content: '\e91f';
}

.fl-setting_initial:before {
  content: '\e920';
}

.fl-setting_logo:before {
  content: '\e921';
}

.fl-setting_other:before {
  content: '\e922';
}

.fl-setting_profile:before {
  content: '\e923';
}

.fl-setting_remarks:before {
  content: '\e924';
}

.fl-setting_signature:before {
  content: '\e925';
}

.fl-setting_stamp:before {
  content: '\e926';
}

.fl-billingnote:before {
  content: '\e90e';
}

.fl-folder:before {
  content: '\e90f';
}

.fl-summary-chart:before {
  content: '\e910';
}

.fl-invoice:before {
  content: '\e911';
}

.fl-purchaseorder:before {
  content: '\e912';
}

.fl-quotation:before {
  content: '\e913';
}

.fl-trash:before {
  content: '\e914';
}

.fl-inline-block:before {
  content: '\e900';
}

.fl-checkbox-empty:before {
  content: '\e901';
}

.fl-expense:before {
  content: '\e902';
}

.fl-report:before {
  content: '\e905';
}

.fl-basket:before {
  content: '\e906';
}

.fl-star:before {
  content: '\e907';
}

.fl-home:before {
  content: '\e908';
}

.fl-sell:before {
  content: '\e909';
}

.fl-Producttype-product:before {
  content: '\e90a';
}

.fl-Producttype-productstock:before {
  content: '\e90b';
}

.fl-Producttype-service:before {
  content: '\e90c';
}

.fl-cog:before {
  content: '\e90d';
}

.fl-play-circle:before {
  content: '\e93a';
}

.nav > li > a {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.nav > li > a.active {
  text-decoration: underline !important;
  color: #74c3e6 !important;
}

menu {
  font-size: 16px;
  line-height: 1;
  margin: 0;
}

.nav-paddr {
  line-height: 1;
  color: #2898cb;
}

.navbar-nav a:focus {
  outline: 0;
}

html {
  min-height: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  position: relative;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

h2.head-h2 {
  margin-top: 50px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: rsu-regular, sans-serif;
  -webkit-font-smoothing: antialiased;
}

h2 {
  font-size: 27px;
}

.no-padding {
  padding: 30px 0 0 0 !important;
}

section article.heading {
  font-size: 22px;
  font-family: rsu-regular, sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: #666;
  margin-bottom: 10px;
  padding-top: 16px;
}

div.inthenews {
  background-color: #f6f6f6;
  position: relative;
  padding-bottom: 10px;
}

h2.news {
  color: #0481c0;
  font-family: rsu-regular, sans-serif;
}

div.inthenews .thumbnail {
  background-color: transparent;
  border: 0;
  margin-bottom: 45px;
}

div.inthenews .carousel-indicators {
  bottom: -10px;
}

div.inthenews .carousel-indicators .active {
  background-color: transparent;
  border: 1px solid #09c;
}

div.inthenews .carousel-indicators li {
  background-color: #999;
  border: 1px solid #999;
}

#play,
#play:focus,
#play:visited {
  outline: 0;
}

.video-size {
  margin: 0 auto;
}

.video-container {
  padding-top: 0;
  overflow: hidden;
  z-index: 1;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 10px solid white;
  border-radius: 5px;
}

.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 395px;
}

@media screen and (max-height: 375px) {
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: 290px;
  }
}

@media screen and (max-height: 320px) {
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: 240px;
  }
}

.navbar-static-top {
  position: absolute;
  z-index: 999;
  width: 100%;
}

.containerpadding {
  padding: 0 0 0 0;
}

.navbar {
  background-color: #fff;
  border-radius: 0;
  font-size: 14px;
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid #eaeaea;
}

.navbar .btn {
  margin-top: 8px;
}

.navbar span.pipe {
  float: left;
  font-size: 20px;
  padding-bottom: 0;
  padding-top: 8px;
  font-weight: 400;
  color: #999;
}

.icon-dot {
  background-image: url(../images/dot.jpg);
  width: 6px;
  height: 6px;
  top: 50%;
  position: absolute;
  right: 0;
  margin-top: -3px;
  margin-right: 10px;
  z-index: 9;
}

span.icon-circle {
  background-image: url(../images/icon_circle.png);
  width: 9px;
  height: 9px;
  top: 50%;
  position: absolute;
  margin-top: -4px;
  margin-right: 10px;
  z-index: 9;
}

.navbar-fixed-top .navbar,
.navbar-fixed-top .show-down {
  top: 0;
}

.back-to-top .show-up {
  bottom: 4px;
}

@media (min-width: 961px) and (max-width: 1199px) {
  .navbar-fix {
    padding: 0;
  }
}

section#banner {
  background-color: #000;
  background-position: bottom center;
  width: 100%;
  height: 439px;
  position: relative;
  margin-top: 51px;
}

section#overview {
  background-image: url(../images/section/FeatureOverview/vdo-screenshot_kridsada-fade.jpg);
  background-position: center right;
  width: 100%;
  height: 280px;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
}

#watch-overview {
  background-image: url(../images/section/FeatureOverview/Bluesquare-vdotextBG.png);
  background-position: bottom left;
  max-width: 1004px;
  width: 100%;
  height: 280px;
  position: relative;
  background-repeat: no-repeat;
  float: left;
  cursor: pointer;
}

.opendoc-banner {
  background-image: url(../images/section/Features/GradientColor_Banner.png);
  background-position: center right;
  width: 100%;
  min-height: 150px;
  position: relative;
  background-repeat: repeat;
  background-size: cover;
  height: auto;
}

.opendoc-table {
  display: table;
  width: 100%;
}

.sptop {
  font-size: 26pt;
}

.spbot {
  font-size: 22pt;
}

.hyellowtop {
  font-size: 44pt;
  color: #f8e71c;
  padding-left: 10px;
  padding-right: 10px;
}

.hyellowbot {
  font-size: 40pt;
  color: #f8e71c;
  padding-left: 10px;
  padding-right: 10px;
}

h2.sptop,
h2.hyellowtop,
h2.hyellowbot,
h2.spbot {
  display: inline;
}

.opendoc-table-content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  height: 150px;
  color: #fff;
  font-family: rsu-regular, sans-serif;
  line-height: 3em;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.play-circle {
  font-size: 55px;
}

.hiddenimage-overview {
  height: 478px !important;
  background-image: none !important;
  margin-top: 45px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (max-width: 1530px) and (min-width: 621px) {
  #watch-overview {
    background-image: url(../images/section/FeatureOverview/Bluesquare-vdotextMD.png) !important;
    max-width: 580px !important;
  }

  .overview-feature {
    width: 580px !important;
  }
}

@media (max-width: 1220px) {
  section#overview {
    background-size: cover !important;
  }

  .play-circle {
    font-size: 45px !important;
  }
}

@media (max-width: 870px) {
  #iframeoverviews {
    width: 640px;
    height: 360px;
  }

  .overview-feature-youtube {
    width: 640px !important;
    height: 360px !important;
  }

  .hiddenimage-overview {
    height: 360px !important;
  }
}

@media (max-width: 680px) {
  .opendoc-table-content {
    padding-top: 10px;
  }
}

@media (max-width: 640px) {
  #iframeoverviews {
    width: 480px !important;
    height: 270px !important;
  }

  .overview-feature-youtube {
    width: 480px !important;
    height: 270px !important;
  }

  .hiddenimage-overview {
    height: 270px !important;
  }
}

@media (max-width: 480px) {
  #iframeoverviews {
    width: 360px !important;
    height: 203px !important;
  }

  .overview-feature-youtube {
    width: 360px !important;
    height: 203px !important;
  }

  .hiddenimage-overview {
    height: 203px !important;
  }

  .sptop {
    font-size: 18pt !important;
  }

  .spbot {
    font-size: 16pt !important;
  }

  .hyellowtop {
    font-size: 34pt !important;
  }

  .hyellowbot {
    font-size: 32pt !important;
  }
}

@media (max-width: 320px) {
  #iframeoverviews {
    width: 320px !important;
    height: 180px !important;
  }

  .overview-feature-youtube {
    width: 320px !important;
    height: 180px !important;
  }

  .hiddenimage-overview {
    height: 180px !important;
  }
}

@media (max-width: 620px) {
  section#overview {
    background-position: center center !important;
  }
}

.overview-feature {
  display: table-cell;
  height: 280px;
  width: 1004px;
  vertical-align: middle;
  text-align: center;
  color: #fff;
}

.overview-feature-youtube {
  margin: 0 auto;
  height: 478px;
  width: 853px;
  vertical-align: middle;
  text-align: center;
  color: #fff;
}

.overview-alwaystop {
  position: fixed !important;
  right: 30px;
  top: 60px;
  padding-top: 0 !important;
  z-index: 9999;
  width: 350px !important;
  height: 197px !important;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.iframeoverview {
  width: 350px !important;
  height: 197px !important;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

@-webkit-keyframes bounce {
  0% {
    bottom: 0;
  }

  25%,
  75% {
    bottom: 10px;
  }

  50% {
    bottom: 10px;
  }

  100% {
    bottom: -5px;
  }
}

@-moz-keyframes bounce {
  0% {
    bottom: 0;
  }

  25%,
  75% {
    bottom: 10px;
  }

  50% {
    bottom: 10px;
  }

  100% {
    bottom: -5px;
  }
}

@-o-keyframes bounce {
  0% {
    bottom: 0;
  }

  25%,
  75% {
    bottom: 10px;
  }

  50% {
    bottom: 10px;
  }

  100% {
    bottom: -5px;
  }
}

@keyframes bounce {
  0% {
    bottom: 0;
  }

  25%,
  75% {
    bottom: 10px;
  }

  50% {
    bottom: 10px;
  }

  100% {
    bottom: -5px;
  }
}

section#banner .content__more-arrow__icon {
  -webkit-animation: bounce 1.5s infinite;
  animation: bounce 1.5s infinite;
  -moz-animation: bounce 1.5s infinite;
  bottom: 0;
  position: relative;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  margin: 10px auto auto;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
  background-size: contain;
}

section#banner .content__more-arrow {
  position: absolute;
  bottom: 5px;
  text-align: center;
}

section#banner .content__more-arrow a {
  color: #fff;
  display: inline-block;
  font-family: rsu-regular, sans-serif;
  font-size: 32px;
  bottom: 0;
}

section#banner .content__more-arrow a:focus {
  outline: 0;
}

section#banner .content__more-arrow a:hover {
  text-decoration: none;
  color: #39c;
}

section#banner .banner-overlay {
  background-color: #000;
  opacity: 0.3;
  height: 80%;
  position: absolute;
  width: 100%;
  top: 10%;
}

section#banner .banner-content-container {
  height: 100%;
  max-width: 1240px;
  position: relative;
  margin: 0 auto;
  padding-left: 35px;
}

section#banner .banner-content {
  text-align: left;
  position: relative;
  padding-top: 81px;
  max-height: 524px;
}

section#banner .banner-content .text-body {
  color: #fff;
}

section#banner .banner-content .text-body h1 {
  margin: 0;
  line-height: 60px;
  font-size: 48px;
}

section#banner .banner-content .text-body p {
  font-size: 12px;
  letter-spacing: 1px;
  margin: 0;
  line-height: 20px;
}

section#banner .banner-content .btn-control {
  font-size: 20px;
  margin-top: 10px;
}

section#banner .banner-content .btn-control a[class^='btn'],
section#banner .banner-content .btn-control a[class*='btn'] {
  background-color: transparent;
  border: 1px solid #2898cb;
  border-radius: 4px;
  height: 50px;
  width: 260px;
  margin-bottom: 10px;
  font-family: inherit;
  font-size: inherit;
  color: #fff;
  line-height: 35px;
  border-radius: 2px;
  background-color: #27c229;
}

section#banner a[class^='btn']:hover,
section#banner a[class*='btn']:hover {
  background-color: #21a623 !important;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

section#banner .banner-content .btn-control .btn-sign-up {
  border: 1px solid rgba(255, 255, 255, 0.7) !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: #515151 !important;
}

.features {
  margin-top: 25px;
}

.btn-features {
  font-size: 16px;
  margin-top: 5px;
  background-color: #0099cc !important;
  border: 1px solid #0099cc !important;
  color: #fff !important;
  height: 35px;
  width: 125px;
  line-height: 22px;
  border-radius: 2px;
}

.btn-features:hover,
.btn-features:focus,
.btn-features:active,
.btn-features.active {
  color: white;
  background-color: #3e90bf !important;
  border-color: #3e90bf;
}

.btn-demo {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  float: left;
  margin-top: 0;
  font-size: 24px;
  background-color: #fff !important;
  border: 1px solid #0099cc !important;
  color: #0099cc !important;
  height: 45px;
  width: 105px;
  line-height: 30px;
  border-radius: 2px;
}

.btn-demo:hover {
  background-color: #0099cc !important;
  border: 1px solid #0099cc !important;
  color: #fff !important;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.features .feature-item {
  text-align: center;
}

section#features .img-wt {
  max-width: 600px !important;
}

.features .feature-item .feature-content h1 {
  color: #515151;
  font-family: rsu-regular, sans-serif;
  font-size: 18px;
  line-height: 22px;
}

.features .feature-item .feature-content p {
  font-size: 20px;
  color: #999;
  text-align: center;
}

.header_underline {
  background-color: #cfcfcf;
  height: 2px;
  width: 20%;
  margin-bottom: 25px;
  padding-bottom: 0;
  margin-top: 10px;
}

.header_underline_white {
  background-color: #fff;
  height: 1px;
  width: 30%;
  padding-bottom: 0;
  margin-top: 10px !important;
}

section#features .screenshot-container {
  background-color: #f6f6f6;
  padding-left: 0;
  padding-right: 0;
}

section#features .screenshot-container img {
  position: inherit;
  top: 20px;
  width: 100%;
  max-width: 700px;
}

@media (max-width: 1199px) {
  section#features .screenshot-container img {
    position: initial !important;
  }

  .newMobile {
    margin-top: 9px;
    max-width: 300px !important;
  }

  .newExMac {
    margin-top: 20px;
  }

  .headEx {
    padding-top: 0 !important;
  }

  section#security .top-security,
  #inventory .screenshot-container,
  #expense .screenshot-container {
    margin-top: 0px !important;
  }
}

@media (max-width: 992px) {
  section#features .screenshot-container img {
    max-width: 500px;
  }

  .img-inv {
    margin-top: 35px;
  }
}

section#features .screenshot-container .icon-bill {
  top: 100px;
  width: 75px;
}

section#features p.head-create {
  font-family: rsu-regular, sans-serif;
  font-size: 28px;
  margin-top: 50px;
  color: #0481c0;
}

@media only screen and (max-width: 1200px) {
  section#features p.head-create {
    font-size: 26px !important;
    line-height: 1;
  }
}

section#features p.content-detail {
  font-size: 16px;
  color: #999;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  section#features p.content-detail {
    text-align: center;
  }
}

section#features .devices {
  bottom: 0;
  margin-top: 20px;
  margin-bottom: 10px;
}

section#features .devices .product-title h2 {
  color: #0481c0;
  font-family: rsu-regular, sans-serif;
}

section#features .devices .product-title {
  font-size: 16px;
  color: #999;
  text-align: center;
  margin-bottom: 20px;
}

section#features .devices-picture {
  bottom: 0;
  position: absolute;
  width: 100%;
  text-align: center;
}

section#features .devices-picture img {
  width: 100%;
  max-width: 883px;
}

section#features .devices-bg {
  background-color: #fff;
  width: 100%;
  text-align: center;
}

section#review h2 {
  color: #0481c0;
  font-family: rsu-regular, sans-serif;
}

section#review div > p {
  display: block;
  margin-right: auto !important;
  margin-left: auto !important;
}

section#pricing .pricing-bg {
  background-color: #fff;
  height: 480px;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.table-cell-btn {
  text-align: center;
  color: #666;
  font-size: 21px;
  line-height: 1.5em;
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: 1px solid #ccc;
  background-color: #f9f9f9;
}

section#pricing .pricing-content {
  margin-top: 30px;
}

section#pricing .table-pricing-sm {
  border-radius: 5px;
  text-align: center;
  border: 1px solid #fff;
  position: relative;
}

section#pricing .table-pricing-sm .panel-default {
  border: 0;
}

section#pricing .table-pricing-sm .panel-group {
  margin-bottom: 0;
}

section#pricing .table-pricing-sm .panel-body {
  padding: 0;
}

section#pricing .table-pricing-sm {
  margin: 0 15px;
}

section#pricing .table-pricing-sm .panel-heading {
  color: #fff;
  border: 0;
  background-color: rgba(50, 120, 164, 1);
}

section#pricing .table-pricing-sm .premium-tab-plus .panel-heading {
  color: #fff;
  border: 0;
  background-color: #d90;
}

section#pricing .table-pricing-sm .premium-tab-pro .panel-heading {
  color: #fff;
  border: 0;
  background-color: #6b3c94 !important;
}

section#pricing .table-pricing-sm .premium-tab-pro .price-row {
  background-color: #6b3c94 !important;
}

section#pricing .table-pricing-plus .panel-heading {
  color: #fff;
  border: 0;
  background-color: rgba(50, 120, 164, 1);
}

section#pricing .table-pricing-sm .price-row {
  color: #fff;
  border: 0;
  background-color: #3ba0db;
  font-size: 26px;
  line-height: 50px;
}

section#pricing .table-pricing-sm .premium-tab-plus .price-row {
  color: #fff;
  border: 0;
  background-color: #efa600;
  font-size: 26px;
  line-height: 50px;
}

section#pricing .panel.head-free .panel-heading {
  background-color: #14a795 !important;
}

section#pricing .title-free {
  background: rgb(2, 214, 187);
  background: -moz-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(2, 214, 187, 1)),
    color-stop(100%, rgba(2, 200, 165, 1))
  );
  background: -webkit-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: -o-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: -ms-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: linear-gradient(to bottom, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#02d6bb', endColorstr='#02c8a5', GradientType=0);
}

section#pricing .table-pricing-sm .accordion-toggle,
section#pricing .table-pricing-sm .accordion-toggle:hover,
section#pricing .table-pricing-sm .accordion-toggle:focus {
  text-decoration: none;
}

section#pricing .table-pricing-sm .accordion-toggle .panel-heading h4:after {
  font-family: 'Glyphicons Halflings';
  content: '\e114';
  float: right;
}

section#pricing .table-pricing-sm .accordion-toggle.collapsed .panel-heading h4:after {
  content: '\e080';
}

section#pricing .table-pricing-sm .panel-heading h4 {
  font-size: 26px;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

section#pricing .table-pricing-sm .table-body .table-cell:first-child {
  width: 70%;
}

section#pricing .right-doubled-border {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 1px 0 0 0 rgba(177, 179, 164, 1);
  -moz-box-shadow: 1px 0 0 0 rgba(177, 179, 164, 1);
  box-shadow: 1px 0 0 0 rgba(177, 179, 164, 1);
  padding: 0 1px 0 0;
  background-color: rgba(177, 179, 164, 0.75);
  position: relative;
}

section#pricing .table-buy {
  background: rgb(63, 166, 222);
  background: -moz-linear-gradient(top, rgba(63, 166, 222, 1) 0%, rgba(47, 136, 210, 1) 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(63, 166, 222, 1)),
    color-stop(100%, rgba(47, 136, 210, 1))
  );
  background: -webkit-linear-gradient(top, rgba(63, 166, 222, 1) 0%, rgba(47, 136, 210, 1) 100%);
  background: -o-linear-gradient(top, rgba(63, 166, 222, 1) 0%, rgba(47, 136, 210, 1) 100%);
  background: -ms-linear-gradient(top, rgba(63, 166, 222, 1) 0%, rgba(47, 136, 210, 1) 100%);
  background: linear-gradient(to bottom, rgba(63, 166, 222, 1) 0%, rgba(47, 136, 210, 1) 100%);
  // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#3fa6de', endColorstr='#2f88d2', GradientType=0)
}

section#pricing .table-free {
  background: rgb(2, 214, 187);
  background: -moz-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(2, 214, 187, 1)),
    color-stop(100%, rgba(2, 200, 165, 1))
  );
  background: -webkit-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: -o-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: -ms-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: linear-gradient(to bottom, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#02d6bb', endColorstr='#02c8a5', GradientType=0)
}

section#pricing .table-plus {
  background: rgb(239, 166, 0);
  background: -moz-linear-gradient(top, rgb(239, 166, 0) 0%, rgb(221, 153, 0) 100%);
  background: -webkit-linear-gradient(top, rgb(239, 166, 0) 0%, rgb(221, 153, 0) 100%);
  background: linear-gradient(to bottom, rgb(239, 166, 0) 0%, rgb(221, 153, 0) 100%);
  // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#efa600', endColorstr='#dd9900', GradientType=0)
}

h2.heading.blue {
  color: #0481c0;
  display: inline-block;
  margin-bottom: 0;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}

.clear-blck:after {
  content: '';
  display: block;
  clear: both;
}

.resize {
  width: 100%;
  height: auto;
}

.job-banner {
  margin: 15px 0 0 0;
}

.job-banner .image {
  width: 60%;
  float: left;
  text-align: right;
}

.job-banner .image img {
  width: 100%;
  max-width: 460px;
}

.job-banner .detail {
  width: 35%;
  float: right;
  font-size: 16px;
  line-height: 1.5em;
  color: #0481c0;
  text-align: left;
  margin: 2% 0 0 0;
}

.job-banner .detail b {
  font-family: rsu-regular, sans-serif;
  font-size: 28px;
  line-height: 1.5em;
}

a.btnBlue,
a.btnBlue:link,
a.btnBlue:visited {
  font-size: 16px;
  line-height: 1.5em;
  margin: 8px 0 0 0;
  color: #2898cb;
  background: #fff;
  width: 210px;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 2px;
  border: 1px solid #2898cb;
}

a.btnBlue:hover,
a.btnBlue:active {
  background: #2898cb;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  section#pricing .table-buy {
    background: inherit !important;
    filter: initial !important;
  }

  section#pricing .table-free {
    background: inherit !important;
    filter: initial !important;
  }

  section#pricing .table-plus {
    background: inherit !important;
    filter: initial !important;
  }

  section#banner .banner-content-container {
    margin-left: auto !important;
    padding-left: 0 !important;
  }
}

section#pricing .table-free .table-badge {
  background-color: #3898d8;
}

section#pricing .table-premium .table-badge span {
  background-color: #fff;
  color: #aaa9a9;
}

section#pricing .table-free .table-badge span {
  background-color: #2d87c4;
  color: #fff;
}

section#pricing .table-pricing .table-heading {
  color: #fff;
  font-family: rsu-regular, sans-serif;
  font-size: 28px;
  padding-top: 30px;
}

section#pricing .sm-font {
  font-size: 20px !important;
}

section#pricing .table-pricing .table-badge {
  height: 100px;
  position: relative;
  font-size: 20px;
}

section#pricing .table-pricing .table-badge span {
  width: 125px;
  border-radius: 30px;
  display: block;
  height: 30px;
  line-height: 30px;
  margin-left: -62px;
  top: 50%;
  position: absolute;
  left: 50%;
  margin-top: -15px;
  font-size: 20px;
}

section#pricing .table-pricing ul {
  margin: 0;
  padding: 0;
}

section#pricing .table-pricing ul li {
  background-color: #fff;
  list-style: none;
  color: #666;
  padding: 12px;
  font-size: 20px;
  line-height: 1em;
}

section#pricing .plans-head-tab {
  position: absolute;
  border-bottom: 1px solid #3279a4;
  z-index: 9;
  right: 0;
  top: 0;
  background-color: #3e90bf;
  color: #fff;
  -webkit-box-shadow: inset 0 -20px 20px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 -20px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -20px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 0 0 1px 0;
  width: 100%;
  text-align: center;
}

section#pricing .basic-plan {
  position: absolute;
  border-bottom: 1px solid #10907f;
  z-index: 9;
  right: 0;
  top: 0;
  background-color: #14a795 !important;
  color: #fff;
  -webkit-box-shadow: inset 0 -20px 20px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 -20px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -20px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 0 0 1px 0;
  width: 100%;
  text-align: center;
}

section#pricing .plans-head-plus {
  position: absolute;
  border-bottom: 1px solid #dd9000;
  z-index: 9;
  right: 0;
  top: 0;
  background-color: #efa600 !important;
  color: #fff;
  -webkit-box-shadow: inset 0 -20px 20px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 -20px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -20px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 0 0 1px 0;
  width: 100%;
  text-align: center;
}

section#pricing .table-pricing .body-list div.row:nth-child(odd) {
  background-color: #ced3bc !important;
}

section#pricing .table-pricing .body-list .table-body .table-cell {
  display: table-cell;
  color: #4e545e;
  font-size: 16px;
  line-height: 1.5em;
  font-family: 'cs_prakasregular', sans-serif;
}

section#pricing .table-pricing .body-list .table-header .table-cell {
  color: #fff;
  display: table-cell;
}

section#pricing .table-pricing .body-list .table-header .table-cell:first-child {
  background: rgb(2, 214, 187);
  background: -moz-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(2, 214, 187, 1)),
    color-stop(100%, rgba(2, 200, 165, 1))
  );
  background: -webkit-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: -o-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: -ms-linear-gradient(top, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  background: linear-gradient(to bottom, rgba(2, 214, 187, 1) 0%, rgba(2, 200, 165, 1) 100%);
  // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#02d6bb', endColorstr='#02c8a5', GradientType=0);
  border-right: 1px solid #02bda1;
  padding: 0 1px 0 0;
  position: relative;
  width: 60%;
}

section#pricing .table-pricing .body-list .table-footer {
  column-span: all;
  -webkit-column-span: all;
  -moz-column-span: all;
  -ms-column-span: all;
  -o-column-span: all;
}

section#pricing .table-pricing .body-list .table-body {
  width: 100%;
  display: table-row-group;
}

section#pricing .table-pricing .body-list .table-body .table-cell {
  padding: 10px;
  vertical-align: middle;
}

section#pricing .table-pricing .body-list .table-body .glyphicon {
  color: #39c;
}

section#pricing .table-pricing .body-list .table-body .table-cell:nth-child(odd),
section#pricing .table-pricing .body-list .table-body .table-cell:nth-child(2) {
  min-width: 220px;
  position: relative;
}

section#pricing .table-pricing .body-list .table-body .table-cell:nth-child(1),
section#pricing .btn-bottom.hiden-sm {
  text-align: left;
  padding-left: 50px;
  width: 100%;
  max-width: 540px;
  padding-right: 20px;
}

section#pricing .table-pricing .body-list .table-body .table-row {
  background-color: #fff;
}

section#pricing .table-pricing .box-basic .body-list .table-body div.table-row:nth-child(odd) {
  background-color: rgba(39, 194, 41, 0.05) !important;
}

section#pricing .table-pricing .box-premium .body-list .table-body div.table-row:nth-child(odd) {
  background-color: rgba(40, 152, 203, 0.05) !important;
}

section#pricing .table-pricing .box-premium165 .body-list .table-body div.table-row:nth-child(odd) {
  background-color: rgba(107, 60, 148, 0.05) !important;
}

section#pricing .table-pricing .box-premium330 .body-list .table-body div.table-row:nth-child(odd) {
  background-color: rgba(239, 166, 0, 0.05) !important;
}

section#pricing .table-pricing .body-list .table-row {
  display: table-row;
}

section#pricing .table-pricing .table-footer {
  height: 80px;
  line-height: 80px;
  background-color: #ced3bc;
  font-size: 20px;
  color: #666;
}

section#pricing .table-pricing .table-footer .btn {
  border: medium none;
  border-radius: 2px;
  height: 34px;
  margin-top: 8px;
  color: #fff !important;
  padding: 8px 20px !important;
  width: 132px;
  line-height: 20px;
}

section#review p {
  text-align: center;
  font-size: 16px;
  color: #999;
  max-width: 700px;
}

section#review p.name {
  font-size: 18px;
  color: #6eb0d7;
  margin: 0;
}

section#review button {
  background-color: transparent;
  border: 1px solid #fff;
}

section#review .arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 32.5px 22px 32.5px;
  border-color: transparent transparent #f0f0f0 transparent;
  margin: 0 auto;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s ease-in 1 forwards;
}

.is-paused {
  animation-play-state: paused;
}

section#review button.active {
  -webkit-transition: border 200ms ease;
  -moz-transition: border 200ms ease;
  -ms-transition: border 200ms ease;
  -o-transition: border 200ms ease;
  transition: border 200ms ease;
  border: 2px solid #39c;
}

section#review img {
  max-width: 130px;
}

.back-to-top {
  position: fixed;
  bottom: 4px;
  right: 10px;
  text-decoration: none;
  color: #000;
  font-size: 12px;
  display: none;
}

.button-top {
  width: 49px;
  height: 49px;
  display: block;
  position: relative;
  background: url(../images/backtotop.png) no-repeat;
}

.btn-go-to-top {
  height: 85px;
  text-align: center;
}

.btn-go-to-top .button-top {
  -webkit-animation: bounce-up 1.5s infinite;
  animation: bounce-up 1.5s infinite;
  -moz-animation: bounce-up 1.5s infinite;
}

@-webkit-keyframes bounce-up {
  0% {
    top: 15%;
  }

  25%,
  75% {
    top: 50%;
  }

  50% {
    top: 25%;
  }

  100% {
    top: 15%;
  }
}

@-moz-keyframes bounce-up {
  0% {
    top: 15%;
  }

  25%,
  75% {
    top: 50%;
  }

  50% {
    top: 25%;
  }

  100% {
    top: 15%;
  }
}

@-o-keyframes bounce-up {
  0% {
    top: 15%;
  }

  25%,
  75% {
    top: 50%;
  }

  50% {
    top: 25%;
  }

  100% {
    top: 15%;
  }
}

@keyframes bounce-up {
  0% {
    top: 15%;
  }

  25%,
  75% {
    top: 50%;
  }

  50% {
    top: 25%;
  }

  100% {
    top: 15%;
  }
}

.slide-down {
  top: 0 !important;
}

.invoice-container .navbar {
  height: 60px;
  margin-bottom: 0;
  background-color: #2599cd;
}

.invoice-container .btn-toggle-cover-close {
  margin-top: 8px;
  margin-left: 10px;
}

.invoice-container .btn-toggle-cover-close span {
  top: 4px;
}

.invoice-container .step {
  text-align: center;
  font-family: rsu-regular, sans-serif;
}

.invoice-container .step .icon-step img {
  max-width: 100%;
  width: 40px;
}

.invoice-container .step h1 {
  font-size: 18px !important;
  color: #999999 !important;
}

.invoice-container .step p {
  font-size: 18px;
  color: #666;
}

.invoice-container .web-view {
  display: block;
  height: 100%;
  margin-bottom: 20px;
  color: #999999 !important;
}

.invoice-container .web-view iframe {
  border: none;
  width: 100%;
  height: 110%;
  display: block;
}

.top-menu {
  left: 0;
  position: absolute;
  right: 0;
  z-index: 100;
}

.top-menu .navbar {
  background-color: transparent;
  border-radius: 0;
  font-size: 18px;
  margin-bottom: 0;
  border-bottom: 0 solid #eaeaea;
}

.top-menu .navbar-nav > li > a {
  color: #09c;
  font-size: 22px;
}

.menubar {
  position: relative;
  height: 40px;
  background-color: #fff;
  opacity: 0.97;
  display: none;
  box-shadow: inset 0 5px 25px -12px #999;
  -webkit-box-shadow: inset 0 5px 25px -12px #999;
}

#navmenubar {
  display: table;
}

#navmenubar li {
  background: url(../images/icon_circle.png) 0 center no-repeat;
  padding-left: 15px;
  list-style-position: inside;
  display: table-cell;
  text-align: center;
  margin-right: 20px;
}

#navmenubar li > a:hover,
#navmenubar li > a:focus {
  text-decoration: none;
  color: #0481c0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

@media (min-width: 961px) {
  .navbar-nav > li > a {
    padding-right: 10px !important;
    padding-left: 5px !important;
  }

  .hidden-big {
    display: none !important;
  }

  .navbar-header {
    width: 240px;
  }
}

@media (max-width: 960px) {
  .navbar-header {
    width: auto;
  }

  .navbar-nav > li > a {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .hidden-small {
    display: none !important;
  }
}

.hover-focus:hover,
.hover-focus:focus {
  text-decoration: none;
  color: #0481c0 !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 960px) and (min-width: 768px) {
  .hidden-sm2 {
    display: none !important;
  }
}

@media (max-width: 1199px) and (min-width: 961px) {
  .hidden-md2 {
    display: none !important;
  }
}

.navmenu {
  color: #999;
}

.top-menu .navbar-nav > .active > a,
.top-menu .navbar-nav > .active > a:hover,
.top-menu .navbar-nav > .active > a:focus {
  background-color: transparent;
  color: #fff;
}

.top-menu .navbar span.pipe {
  color: #fff;
  float: left;
  font-size: 25px;
  padding-bottom: 0;
  padding-top: 8px;
  font-weight: 400;
}

.top-menu .navbar span.icon-dot {
  background-image: url(../images/dot.jpg);
  width: 6px;
  height: 6px;
  top: 50%;
  position: absolute;
  right: 0;
  margin-top: -3px;
  margin-right: -3px;
  z-index: 9;
}

.top-menu .navbar-nav > li > a:active,
.top-menu .navbar-nav > li > a:hover,
.top-menu .navbar-nav > li > a:focus {
  background-color: transparent;
  color: #fff;
}

.top-menu .navbar-nav > li.lang {
  margin-left: -7px;
}

.top-menu .navbar .btn {
  margin-top: 8px;
}

.invoice-container .page-header {
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.invoice-container .page-header .container {
  height: 100%;
  padding: 0;
}

.invoice-container .page-header h1 {
  margin: 0;
  color: #c1c1c1;
  font-size: 24px;
  line-height: 60px;
}

.invoice-container .page-header h1.page-title {
  padding-left: 10px;
  display: inline;
}

.invoice-container .page-header div.actions {
  padding-top: 8px;
  margin-right: -15px;
}

.invoice-container .page-header .title-container {
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}

.invoice-container .btn-login {
  border: none;
  background: #fff;
  color: #25aae2;
  border: medium none;
  border-radius: 2px;
  height: 34px;
  margin-top: 8px;
  width: 127px;
  font-size: 18px;
}

.dialog {
  height: 100%;
}

.dialog-content {
  position: relative;
  padding: 0;
}

.dialog-content h1.title {
  color: #2599cd;
  text-align: center;
  font-family: rsu-regular, sans-serif;
  margin: 0;
  margin-bottom: 10px;
}

.dialog-content p {
  color: #25aae2;
  font-size: 18px;
}

.dialog-content form .form-group .btn-submit {
  background-color: #25aae2;
  border: none;
  color: #fff;
  border-radius: 4px;
  height: 44px;
  font-size: 18px;
}

.dialog-content form .form-group .btn-signup {
  background-color: #25aae2;
  border: none;
  color: #fff;
  border-radius: 4px;
  height: 44px;
  font-size: 18px;
}

.dialog-content form .form-group label {
  font-weight: 400;
  font-size: 20px;
  color: #999;
}

.dialog-content form .form-group .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
  font-size: 20px;
}

.fancybox-skin .fancybox-close {
  display: none;
}

.dialog-content .dialog-border {
  border: 1px solid #25aae2;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px;
}

.dialog-content .btn-close-dialog {
  position: absolute;
  background-color: transparent;
  background-image: url(../images/closebtn.png);
  border: none;
  height: 24px;
  text-indent: -9999px;
  width: 24px;
  background-position: center;
  top: 0;
  right: 0;
  z-index: 999;
}

.text-forgot:link,
.text-forgot:hover,
.text-forgot:visited {
  font-size: 15px;
  text-decoration: none;
}

.fancybox-wrap {
  background-color: #fff;
  border-bottom: 0 none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.fancybox-close {
  right: 10px;
  top: 10px;
}

.fancybox-inner {
  overflow: hidden;
}

#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url(../images/closebtn.jpg);
  width: 25px;
  height: 25px;
  background-size: cover;
  background-position: 0 !important;
}

.btn-primary {
  color: #fff;
  background: var(--app_color);
  border-color: var(--app_color_hover);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  color: #fff;
  background: var(--app_color);
  border-color: var(--app_color_hover);
}

.btn-pricing {
  color: #fff;
  background-color: #27c229;
  border-color: #27c229;
  border-radius: 2px;
  font-size: 16px;
  height: 30px;
  position: relative;
  top: -13px;
  width: 125px;
  line-height: 1em;
}

.btn-pricing:hover,
.btn-pricing:focus,
.btn-pricing:active,
.btn-pricing.active {
  background-color: #21a623 !important;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btn-plus {
  color: #fff;
  background-color: #09c;
  border-color: #09c;
  border-radius: 2px;
  font-size: 16px;
  height: 30px;
  width: 115px;
  line-height: 1em;
}

.btn-plus:hover,
.btn-plus:focus,
.btn-plus:active,
.btn-plus.active {
  color: white;
  background-color: #3e90bf;
  border-color: #3e90bf;
}

.trowserView .trowser {
  visibility: visible;
}

.trowser.slide-down {
  -webkit-animation: slideDown 0.35s ease-in-out both;
  animation: slideDown 0.35s ease-in-out both;
  -moz-animation: slideDown 0.35s ease-in-out both;
}

@-webkit-keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
  }
}

@-moz-keyframes slideDown {
  from {
    -moz-transform: translateY(-100%);
  }
}

@-o-keyframes slideDown {
  from {
  }

  from {
    -o-transform: translateY(-100%);
  }
}

@keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-webkit-keyframes slideUp {
  from {
  }

  to {
    -webkit-transform: translateY(-100%);
  }
}

@-moz-keyframes slideUp {
  from {
  }

  to {
    -moz-transform: translateY(-100%);
  }
}

@-o-keyframes slideUp {
  from {
  }

  to {
    -o-transform: translateY(-100%);
  }
}

@keyframes slideUp {
  from {
  }

  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.trowser.slide-up {
  -webkit-animation: slideUp 0.35s ease-in-out both;
  animation: slideUp 0.35s ease-in-out both;
  -moz-animation: slideUp 0.35s ease-in-out both;
}

.trowser {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  outline: 0;
  left: 0;
  background: white;
  z-index: 1040;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

body.shell.modal-open {
  padding-right: 0 !important;
}

.modal-viewport {
  width: 100%;
  height: 100%;
  display: flex;
  display: -webkit-flex;
}

.flexBoxStack {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.invoice-container header {
  position: relative;
  background-color: #2599cd;
  color: #fff;
  height: auto !important;
}

.invoice-container .page-header {
  color: #999;
  background: #fff;
}

.modal-viewport .title {
  line-height: 60px;
  color: #fff;
  font-size: 2.2rem;
  padding: 0 0 0 19px;
}

.modal-viewport header i {
  width: 40px;
  height: 60px;
  padding: 0;
  cursor: pointer;
  font-size: 24px;
  color: white;
  line-height: 60px;
  text-align: center;
}

.flexBoxStack .scroller {
  overflow-y: auto;
  padding-bottom: 20px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.modal-viewport header .leftDivider {
  border-left: 1px solid #efefef;
}

.trowserView .trowser .hide {
  display: none;
}

// .modal {
//   display: block
// }

.dialog-header-error {
  background-color: #d2322d;
}

.dialog-header-wait {
  background-color: #428bca;
}

.dialog-header-notify {
  background-color: #eee;
}

.dialog-header-confirm {
  background-color: #333;
}

.dialog-header-confirm h4,
.dialog-header-confirm span,
.dialog-header-error h4,
.dialog-header-error span,
.dialog-header-wait h4,
.dialog-header-wait span {
  color: #fff;
}

.pad {
  padding: 25px;
}

.img-mobile {
  background-image: url(../images/appstorebtn.png);
  background-size: cover;
  position: static !important;
  width: 130px;
  height: 45px;
  max-width: none !important;
  min-width: initial !important;
}

.img-mobile-android {
  background-image: url(../images/google-play-badge.png);
  background-size: cover;
  position: static !important;
  width: 154px;
  height: 45px;
  max-width: none !important;
  min-width: initial !important;
}

@media (max-device-width: 480px) {
  .features .feature-item .feature-content h1 {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 320px) {
  section#banner .banner-content-container .banner-content {
    padding-top: 20px !important;
  }

  .features .feature-item .feature-content h1 {
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1439px) {
  .macpro {
    width: 70% !important;
  }
}

@media only screen and (max-width: 767px) {
  .macpro {
    margin: 0 auto !important;
  }

  .free-height {
    height: auto !important;
  }

  .newExMac {
    margin-top: 20px !important;
    float: none !important;
    margin: 0 auto;
  }

  .clear-width {
    max-width: none !important;
    padding-left: 0;
  }

  .img-inv {
    float: none !important;
    margin: 0 auto !important;
    padding-top: 20px;
  }

  .newMobile {
    float: none !important;
    margin: 0 auto;
    width: 250px !important;
  }

  .clear-padd {
    padding-left: 0 !important;
  }

  section#features p.head-create {
    margin-top: 20px !important;
    text-align: center !important;
  }

  section#features #inventory-header {
    text-align: center !important;
  }

  section#banner {
    background-image: url(../images/banner_cloud.png) !important;
    background-position: left center !important;
  }

  .features .feature-item .feature-content h1 {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  section#security .top-security,
  #inventory .screenshot-container,
  #expense .screenshot-container,
  #Div1 .screenshot-container {
    min-height: 0px !important;
  }
}

@media only screen and (max-width: 420px) {
  .navbar-toggle {
    margin-right: 5px;
    padding: 9px 7px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sptop {
    font-size: 22pt;
  }

  .spbot {
    font-size: 20pt;
  }

  .hyellowtop {
    font-size: 40pt;
    color: #f8e71c;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hyellowbot {
    font-size: 36pt;
    color: #f8e71c;
    padding-left: 10px;
    padding-right: 10px;
  }

  section#security .top-security,
  #inventory .screenshot-container,
  #expense .screenshot-container,
  #Div1 .screenshot-container {
    min-height: 0px !important;
  }
}

@media only screen and (min-width: 768px) {
  section#banner {
    background-image: url(../images/banner_cloud.png) !important;
  }

  .textright {
    text-align: right !important;
  }
}

@media only screen and (max-width: 960px) {
  .navbar span.icon-dot {
    display: none !important;
  }

  .li-signup {
    padding-right: 0 !important;
  }

  .job-banner .detail {
    font-size: 16px;
    line-height: 1.5em;
  }

  .job-banner .detail b {
    font-size: 24px;
    line-height: 1.5em;
  }
}

@media only screen and (min-width: 1440px) {
  section#banner {
    background-image: url(../images/banner_cloud.png) !important;
  }

  section#features .screenshot-container img {
    max-width: 920px;
    position: absolute !important;
  }

  section#features .screenshot-container {
    background-color: #f6f6f6;
    min-height: 425px;
    padding-left: 0;
    padding-right: 0;
  }

  .text-margintop {
    margin-top: 100px;
  }

  .text-margintop2 {
    margin-top: 110px;
  }

  section#features .img-wt {
    max-width: 600px !important;
  }
}

@media only screen and (min-width: 1200px) {
  section#features .newMobile {
    max-width: 400px !important;
  }

  .container-img {
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;
  }

  .expense-lg {
    position: absolute;
    right: -110px;
  }

  .wt-lg {
    position: absolute;
    right: -25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  section#features .img-wt {
    max-width: 600px !important;
  }

  section#features .screenshot-container img {
    max-width: 920px;
    position: absolute !important;
  }

  section#features .screenshot-container {
    background-color: #f6f6f6;
    min-height: 350px;
    padding-left: 0;
    padding-right: 0;
  }

  .text-margintop {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1199px) {
  .clear-padd {
    padding-left: 35px !important;
  }

  #inventory #inventory-header {
    margin-top: 50px !important;
  }
}

@media only screen and (max-width: 992px) {
  .del-margin {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 767px) {
  section#pricing .pricing-bg {
    height: 180px !important;
  }

  section#banner .banner-content {
    padding-top: 50px !important;
  }

  .padd-r {
    padding-right: 0 !important;
  }

  .sptop {
    font-size: 20pt;
  }

  .spbot {
    font-size: 18pt;
  }

  .hyellowtop {
    font-size: 38pt;
    color: #f8e71c;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hyellowbot {
    font-size: 34pt;
    color: #f8e71c;
    padding-left: 10px;
    padding-right: 10px;
  }

  .job-banner .image {
    width: 100%;
    float: left;
    text-align: center;
  }

  .job-banner .detail {
    width: 100%;
    float: right;
    margin: 15px 0 0 0;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .padd-r {
    padding-right: 5px !important;
  }

  .text-margintop {
    max-width: 550px;
    float: left;
  }

  .wt-lg {
    padding-top: 20px;
  }
}

@media (max-width: 1200px) {
  .vdoReview {
    padding-left: 11px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  section#pricing .pricing-bg {
    height: 180px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  section#security .top-security,
  #inventory .screenshot-container,
  #expense .screenshot-container,
  #Div1 .screenshot-container {
    min-height: 0px !important;
  }
}

@media only screen and (min-width: 992px) {
  .sucurity-container {
    margin-left: 16.66666667%;
    margin-right: 16.66666667%;
  }
}

.icon-rotate {
  top: 4px;
}

.lbl-valid {
  color: #fff;
  font-size: 16px;
}

#security {
  background-color: #fbfbfb;
}

.display-about {
  display: inline-block;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .display-about {
    width: 24%;
  }

  #inventory #inventory-header {
    margin-top: 50px !important;
  }
}

@media (max-width: 600px) {
  .display-about {
    width: 45%;
  }
}

.thumblink:hover {
  position: relative;
  bottom: 5px;
  -webkit-box-shadow: 0 0 1px 1px #e9e9e9;
  box-shadow: 0 0 1px 1px #e9e9e9;
  display: inline;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#navmenubar li {
  line-height: 3;
}

#navmenubar {
  float: none;
}

section#features .icon-new {
  display: inline-table;
  background: #f5a623;
  border-radius: 3px;
  font-family: rsu-regular, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  padding: 0 10px;
  margin-left: 10px;
  position: relative;
  bottom: 3px;
}

@media only screen and (min-width: 1440px) {
  #Div1 .screenshot-container {
    min-height: 520px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  #Div1 .screenshot-container {
    min-height: 420px !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1190px) {
  #invoice .text-margintop {
    padding-top: 20px;
  }
}

@media (min-width: 1200px) {
  #inventory .col-md-6 .text-margintop {
    margin-left: 60px;
  }
}

@media (width: 768px) {
  #invoice p.content-detail,
  #dashboard p.content-detail,
  #inventory p.content-detail {
    text-align: left !important;
  }
}

@media (max-width: 400px) {
  .newline {
    display: block !important;
  }
}

.list-download {
  padding-left: 0;
  list-style: none;
  font-size: 14px;
}

.list-download a:hover {
  text-decoration: none;
}

.list-download .vdo {
  color: #d0021b;
}

.list-download .vdo:hover {
  color: #e49292 !important;
}

.list-download .color-download {
  color: #2898cb;
}

.list-download .color-download:hover {
  color: #74c3e6;
}

.list-download .text-pad {
  padding-left: 20px;
}

.list-download.wth-sm {
  margin-bottom: 10px;
  text-align: center;
}

.premium-valus {
  color: #fff;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 15px;
  position: relative;
  top: -36px;
  height: 110px;
}

.premium-valus p {
  margin: 0 auto;
}

.premium-valus-mobile {
  top: 10px;
  line-height: 14px;
}

.premium-valus-mobile > div {
  text-align: center;
  float: left;
  margin: 0 auto;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -75px;
  width: 148px;
}

.youtube.vdo.big {
  width: 420px;
}

.row.big {
  margin: 0 auto;
}

@media (max-width: 767px) {
  section#features .download-document {
    display: table;
    margin: 0 auto;
  }
}

@media (min-width: 670px) {
  .content-vdo.big {
    width: 450px !important;
  }
}

@media (max-width: 995px) {
  .content-vdo.big {
    clear: both;
  }
}

@media (max-width: 669px) {
  .row.big {
    margin: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.videoFlow {
  width: 100%;
  height: 265px;
  max-width: 480px;
  background-size: cover;
}

.video {
  border: 1px solid #e9e9e9 !important;
}

.contaniner-video {
}

.video-list {
  max-width: 800px;
  margin: 0 auto;
}

.video-list .a,
a:hover,
a:focus {
  text-decoration: none !important;
}

.video-list .row {
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 40px;
}

.video-list .row.video-head {
  max-width: 400px;
  margin: 0 auto;
}

.video-list .video-head .vdo-content {
  max-width: 400px;
  margin: 0 auto;
}

.video-list .vdo-content {
  max-width: 300px;
  margin: 0 auto;
}

.video-list .video-head .video-detail {
  position: initial;
  padding-top: 20px;
}

.video-list .video-head .video-detail .head {
  margin-bottom: 10px;
}

.video-list .video-head .video-detail .detail {
  margin-bottom: 12px;
}

.video-list .video-detail {
  padding-top: 30px;
}

.video-list .video-detail .head {
  font-size: 16px;
  color: #2898cb;
  line-height: 1.42857143;
  margin-bottom: 2px;
}

.video-list .youtube.video {
  float: left;
  background-size: cover;
}

.vdoReview {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 14px;
}

.vdoReview .vdoReview-content {
  margin: 0 auto;
  max-width: 961px;
}

.vdoReview p {
  margin: 5px;
}

.vdoReview .vdo {
  height: 169px;
  width: 300px;
  background-size: cover;
}

.vdoReview .name {
  font-size: 16px;
  color: #2898cb;
  height: 22px;
  max-width: 300px;
  min-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0 auto;
}

.vdoReview .company {
  color: #45acdb;
  height: 22px;
  max-width: 300px;
  min-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0 auto;
}

.vdoReview .detail {
  color: #666;
  max-width: 300px;
  min-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0 auto;
}

.vdoReview .content-vdo {
  width: 320px;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  max-height: 288px;
  margin-bottom: 20px;
}

section#features .vdoReview .content-vdo .detail {
  margin-bottom: 20px;
}

@media (min-width: 820px) {
  .video-list .vdo-content {
    width: 240px;
    padding-bottom: 135px !important;
    display: inline-block;
  }

  .video-list .row {
    max-width: 100%;
  }

  .video-list .row.video-head {
    max-width: 100%;
  }

  .video-list .video-head .vdo-content {
    width: 500px;
    max-width: 500px;
    padding-bottom: 281px !important;
  }

  .video-list .video-head .video-detail {
    margin-left: 22px;
    padding-top: 70px;
  }

  .video-list .video-detail {
    padding-top: 30px;
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
    max-width: 456px;
  }
}

@media (max-width: 995px) {
  .vdoReview .hidden995 {
    display: none !important;
  }
}

@media (max-width: 669px) {
  .vdoReview .hidden667 {
    display: none !important;
  }
}

@media (max-width: 414px) {
  section.hidden-mobile,
  section#features .box-features .hidden-mobile,
  section#features .box-download .hidden-mobile {
    display: none !important;
  }

  section#features .box.box-features {
    max-width: initial;
    display: flex;
  }

  section#features .box-features .show-mobile {
    margin: auto;
    display: inline-block !important;
  }

  section#features .box-download .show-mobile {
    display: block !important;
  }

  section#features .box-download .show-mobile .download-document {
    display: table;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 95%;
  }

  section#features .imag-features h2.show-mobile {
    max-width: 246px;
    line-height: 32px;
  }

  section#features .box-features .show-mobile .title span {
    display: inline-block;
    width: 88%;
  }

  section#features .list-download .text-pad {
    padding-left: 7px;
  }

  section#features .list-download a.vdo .text-pad {
    padding-left: 3px !important;
  }

  section#features .list-download i.fl-playvdo {
    font-size: 12px;
  }
}

section#features .box-features .show-mobile,
section#features .box-download .show-mobile {
  display: none;
}

section#pricing .table-pricing .body-list .table-header .table-cell:first-child {
  width: 35%;
}

i.fa-star {
  position: relative;
  top: 3px;
  padding-left: 5px;
  color: #f8e71c;
  font-size: 17px;
}

.premium-tab i.fa-star {
  position: static;
  font-size: 22px;
}

@media (max-width: 379px) {
  #banner {
    background-size: cover;
    height: 495px !important;
  }
}

@media (min-width: 380px) and (max-width: 477px) {
  #banner {
    background-size: cover;
    height: 455px !important;
  }
}

@media (min-width: 478px) and (max-width: 767px) {
  #banner {
    background-size: cover;
    height: 425px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #banner {
    background-size: cover;
    height: 455px !important;
  }
}

div.container-contact {
  text-align: center;
  padding: 4.5px 10px;
  background-color: #000;
  font-size: 14px;
  color: #fff;
  border-bottom: 1px solid;
  border-bottom-color: #000;
}

div.container-contact p {
  margin: 0 0 5px;
}

div.container-contact a {
  padding-left: 5px;
}

@media (max-width: 414px) {
  div.container-contact .socialLiveChat {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  section#features p.center-mobile {
    margin-top: 95px;
  }
}

section#downloadTutorial {
  padding: 30px 10px;
  text-align: center;
  font-size: 20px;
  background-color: #32abdc;
}

section#downloadTutorial span {
  vertical-align: text-bottom;
  font-family: rsu-regular;
}

section#downloadTutorial i {
  font-size: 30px;
}

section#downloadTutorial a {
  color: #fff;
}

section#downloadTutorial a:hover {
  text-decoration: none;
  color: #e6e6e6;
}

section#downloadTutorial a:focus {
  text-decoration: none;
}

@media (max-width: 414px) {
  section#downloadTutorial span {
    display: block;
    padding-top: 10px;
  }

  section#downloadTutorial i {
    font-size: 45px;
  }
}

@supports (overflow: -webkit-marquee) and (justify-content: inherit) {
  .modal-dialog iframe {
    height: calc(100% - 30px);
  }
}

@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
  @media screen {
    _:-webkit-full-screen,
    .modal-dialog iframe {
      height: calc(100% - 30px);
    }
  }
}

.premium-tab .panel-heading,
.premium-tab-plus .panel-heading {
  height: 42px;
}

.youtube {
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  overflow: hidden;
  transition: all 200ms ease-out;
  cursor: pointer;
}

.youtube .play {
  content: url(../images/playbutton.svg);
  cursor: pointer;
  position: absolute;
  height: 30px;
  width: 42px;
  top: 50%;
  left: 50%;
  transition: all 0.2s ease-out;
  margin-left: -21px;
  margin-top: -15px;
}

.youtube.vdo:hover > .play {
  content: url(../images/playbuttonred.svg) !important;
}

.youtube.video:hover > .play {
  content: url(../images/playbuttonred.svg) !important;
}

.youtube .play:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

#register {
  background-color: #45acdb;
  color: #fff;
  font-family: rsu-regular, sans-serif;
  padding: 30px 5px;
  line-height: 3em;
}

#register .textbox {
  display: block;
  color: #999;
  padding: 2px 10px 2px 25px;
  width: 280px;
  border: 1px solid #2898cb;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  line-height: 1em;
  font-size: 16px;
  height: 35px;
}

#register .textbox:focus {
  background-color: #fff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  outline: 0;
  border: 1px solid #02ccab !important;
}

.fa-register {
  color: #999;
  font-size: 16px;
  position: absolute;
  left: 7px;
  top: 10px;
}

.text-register {
  position: relative;
  float: left;
}

.button-register {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
}

.register-input {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

section#register .registerbtn {
  border: 1px solid #2898cb;
  border-radius: 2px;
  background-color: #27c229;
  color: #fff;
  height: 35px;
  padding: 8px 10px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 2em;
  transition: all 0.2s ease-in-out;
  -webkit-transition-property: background-color, color, border-color;
  transition-property: background-color, color, border-color;
  min-width: 145px;
  max-width: 353px;
  display: block;
  padding: 0 10px;
  line-height: 33px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin-top: 2px;
}

section#register .registerbtn:hover {
  color: #fff !important;
  background-color: #21a623 !important;
  text-decoration: none !important;
}

section#register input::-webkit-input-placeholder,
section#register textarea::-webkit-input-placeholder {
  color: #999;
}

section#register input:-moz-placeholder,
section#register textarea:-moz-placeholder {
  color: #999;
  opacity: 1;
}

section#register input::-moz-placeholder,
section#register textarea::-moz-placeholder {
  color: #999;
  opacity: 1;
}

section#register input:-ms-input-placeholder,
section#register textarea:-ms-input-placeholder {
  color: #999;
}

.mobile-register-bottom {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
}

#registerForm {
  display: table;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
}

#registerForm .lbl-valid {
  color: #cf0a22;
  font-size: 13px;
  line-height: 1em;
}

#register .textbox.has-error {
  border: 1px solid red !important;
}

@media (max-width: 785px) {
  .squaredFour label:after {
    top: 4px !important;
    left: 2px !important;
  }

  #checkbox-register {
    margin-top: 20px;
  }

  #register .textbox {
    width: 353px;
  }

  .mobile-register-bottom {
    display: inherit !important;
    margin: 0 auto;
    text-align: left;
    max-width: 395px;
  }

  .button-register {
    text-align: left;
    display: block;
    margin-left: 0;
  }

  .register-input {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .register-footer {
    display: block !important;
    max-width: 395px;
    margin-left: 0px !important;
  }

  .squaredFour label {
    width: 13px !important;
    height: 13px !important;
  }

  .span-check {
    top: 3px !important;
    left: 19px !important;
  }
}

@media (max-width: 768px) {
  .registerbtn {
    width: 100%;
  }
}

@media (max-width: 655px) {
  .text-register {
    clear: both;
    padding-right: 0 !important;
  }

  #register .container {
    padding: 0;
  }
}

@media (max-width: 410px) {
  #register .textbox {
    width: 290px;
  }

  .mobile-register-bottom {
    padding: 0;
  }
}

.register-footer {
  line-height: 1em;
}

.squaredFour {
  position: relative;
}

.span-check {
  position: absolute;
  top: 5px;
  left: 25px;
  line-height: 1.5em;
  font-size: 13px;
}

.squaredFour.error label {
  box-shadow: 0px 0px 0px 2px red inset;
}

.squaredFour label {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: relative;
  top: 6px;
  left: 0;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 4px;
  box-shadow: inset 0 1px 1px white, 0 1px 3px rgba(0, 0, 0, 0.5);
}

.squaredFour label:after {
  content: '';
  width: 8px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 2px solid #333;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.squaredFour label:hover::after {
  opacity: 0.5;
}

.squaredFour input[type='checkbox'] {
  visibility: hidden;
}

.squaredFour input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.banner-event a {
  display: inline-block;
  width: 100%;
}

.banner-event img {
  padding-bottom: 20px;
}

.dropdown-other {
  position: relative;
  display: inline-block;
}

a.nav-paddr i.fa-angle-down {
  position: relative;
  bottom: 2px;
  font-size: 10px;
}

.accountant .heading {
  font-family: rsu-regular, sans-serif;
  color: #42a707;
}

.accountant-title {
  padding-bottom: 55px;
}

.accountant-title-content {
  font-family: rsu-regular;
}

.accountant {
  color: #999;
  font-size: 16px;
}

.header-big {
  font-size: 50px;
}

.header-small {
  font-size: 30px;
}

.accountant-feature {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 15px;
  border-bottom: 1px dotted #42a707;
}

@media (min-width: 1600px) {
  .hidden-normal {
    display: none !important;
  }

  .partner-footer {
    background-image: url(../images/Page/Partner/img_partner_shakehand_HD.png);
    height: 391px;
  }
}

@media (max-width: 1601px) {
  .hidden-hd {
    display: none !important;
  }

  .partner-footer {
    background-image: url(../images/Page/Partner/img_partner_shakehand.png);
    height: 283px;
  }
}

.partner-footer {
  width: 100%;
  background-position: center center;
  background-size: cover;
  margin-bottom: 30px;
  margin-top: 50px;
}

.accountant-feature-content-icon {
  display: table-cell;
  padding-left: 45px;
}

.accountant-feature-content {
  display: table-cell;
  padding-left: 15px;
  word-wrap: break-word;
}

.accountant-div {
  padding-bottom: 15px;
}

.accountant-feature img.img-responsive {
  margin: 0 auto;
}

.accountant-table-content {
  display: table-cell;
  vertical-align: middle;
  width: 65%;
  padding-left: 15px;
  padding-right: 15px;
}

.accountant-table-img {
  display: table-cell;
  width: 35%;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
}

.accountant-register {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 100px;
}

.partner-register-btn {
  width: 365px;
  height: 48px;
  font-size: 20px;
  color: #fff;
  background-color: #42a707;
  border: 0;
  padding: 12px 15px;
  border-radius: 2px;
}

@media (max-width: 322px) {
  .col-xs-6 menu2 {
    font-size: 13px;
  }
}

@media (max-width: 377px) {
  .partner-register-btn {
    height: auto !important;
    padding: 2px 5px !important;
    display: table-cell;
  }
}

@media (min-width: 357px) and (max-width: 377px) {
  .partner-register-btn {
    padding: 2px 15px !important;
  }
}

.partner-register-btn:hover,
.partner-register-btn:focus,
.partner-register-btn:active {
  background-color: #8dc63f !important;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  outline: none;
  text-decoration: none !important;
  color: #fff !important;
}

@media (min-width: 961px) {
  li.li-login .col-xs-6 {
    width: inherit;
    padding: 0;
  }
}

@media (max-width: 960px) {
  .accountant .heading {
    text-align: center;
    display: block;
  }

  .accountant-feature-content-icon {
    padding-left: 0 !important;
  }

  li.li-signup {
    padding-left: 0 !important;
  }
}

.headtitle {
  text-align: center;
  margin: 0 auto;
  color: #3398cc;
  padding-bottom: 25px;
  padding-top: 15px;
}

.faq-content span {
  color: #0481c0;
}

.faq-content p {
  text-align: justify;
}

section#pricing .container {
  margin: auto;
}

section#pricing .box-md {
  display: none;
}

section#pricing .box-lg {
  text-align: center;
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 40px;
}

section#pricing .box-lg .col-content {
  width: 24%;
  max-width: 275px;
  text-align: center;
  margin: 0 0.5%;
  display: inline-block;
  position: relative;
}

section#pricing .box-lg #pricing.box {
  padding: 15px 15px;
  height: 470px;
  border-radius: 8px;
  display: table;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  border: 3px solid #27c229;
}

.en section#pricing .box-lg #pricing.box {
  height: 490px;
}

section#pricing .box-md .box {
  max-width: 757px;
  border: 3px solid #27c229;
  margin: 0 auto;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  color: #45545e;
}

section#pricing #pricing.box.box-basic {
  border-color: #27c229;
  color: #27c229;
}

section#pricing #pricing.box.box-premium {
  border-color: #2898cb;
  color: #2898cb;
}

section#pricing #pricing.box.box-premium165 {
  border-color: #6b3c94;
  color: #6b3c94;
}

section#pricing #pricing.box.box-premium330 {
  border-color: #efa600;
  color: #efa600;
}

section#pricing #pricing.box .box-header {
  font-size: 24px;
  font-family: 'Prompt-600', sans-serif;
}

section#pricing .box-lg #pricing.box .box-header {
  text-align: center;
}

section#pricing #pricing.box .box-detail {
  font-size: 16px;
  line-height: 23px;
  font-family: 'cs_prakasregular', sans-serif;
}

section#pricing .box-lg #pricing.box .box-detail {
  margin: auto;
}

section#pricing .box-md #pricing.box .box-detail {
  display: table-cell;
  vertical-align: middle;
  padding-left: 50px;
  width: 100%;
  max-width: 540px;
  padding-right: 10px;
}

section#pricing .box-md #pricing.box .box-pricing {
  min-width: 211px;
  display: table-cell;
  position: relative;
  bottom: 5px;
}

section#pricing #pricing.box .box-detail2 {
  text-align: left;
  color: #4e545e;
  font-size: 16px;
  line-height: 23px;
  margin-top: 20px;
  font-family: 'cs_prakasregular', sans-serif;
}

section#pricing #pricing.box .box-free {
  margin: 10px 0 0 0;
  font-family: 'cs_prakasregular', sans-serif;
}

section#pricing .box-md #pricing.box .box-detail2 {
  max-width: 250px;
  margin-top: 5px;
  margin-bottom: 10px;
}

section#pricing .box-lg #pricing.box.box-basic .box-detail2 {
  margin-top: 41px;
}

section#pricing #pricing.box.box-basic .box-more {
  color: #27c229;
}

section#pricing #pricing.box.box-basic .box-more:hover,
section#pricing #pricing.box.box-basic .box-more.hovered {
  color: #21a623;
}

section#pricing #pricing.box.box-premium .box-more {
  color: #2898cb;
}

section#pricing #pricing.box.box-premium .box-more:hover,
section#pricing #pricing.box.box-premium .box-more.hovered {
  color: #278abb;
}

section#pricing #pricing.box.box-premium165 .box-more {
  color: #6b3c94;
}

section#pricing #pricing.box.box-premium165 .box-more:hover,
section#pricing #pricing.box.box-premium165 .box-more.hovered {
  color: #5b436f;
}

section#pricing #pricing.box.box-premium330 .box-more {
  color: #efa600;
}

section#pricing #pricing.box.box-premium330 .box-more:hover,
section#pricing #pricing.box.box-premium330 .box-more.hovered {
  color: #d09206;
}

section#pricing .box-lg #pricing.box .box-more {
  display: table-footer-group;
  text-align: left;
}

section#pricing #pricing.box .box-more .fa {
  font-size: 20px;
  float: right;
  vertical-align: middle;
  bottom: 1px;
  position: relative;
}

section#pricing .box-md #pricing.box .box-more .fa {
  margin-left: 10px;
  float: none;
  min-width: 13px;
}

section#pricing #pricing.box .box-pricing {
  font-size: 48px;
  line-height: 1.4em;
  font-family: 'Prompt-600', sans-serif;
}

section#pricing #pricing.box .box-pricing .pricing-thb {
  font-size: 28px;
  vertical-align: middle;
  position: relative;
  bottom: 10px;
}

section#pricing #pricing.box .box-pricing .permonth {
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 400;
  position: relative;
  bottom: 10px;
}

section#pricing .box-md #pricing.box .box-pricing .detail {
  display: block;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 400;
  font-family: 'cs_prakasregular', sans-serif;
}

section#pricing #pricing-btn.box .btn {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  font-family: 'cs_prakasregular', sans-serif;
  width: 100%;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer !important;
}

section#pricing #pricing-btn.box .btn:focus {
  outline: none;
}

section#pricing #pricing-btn .btn.box-basic {
  border-color: #27c229;
  background-color: #27c229;
}

section#pricing #pricing-btn .btn.box-basic:hover {
  border-color: #21a623;
  background-color: #21a623;
}

section#pricing #pricing-btn .btn.box-premium {
  border-color: #2898cb;
  background-color: #2898cb;
}

section#pricing #pricing-btn .btn.box-premium:hover {
  border-color: #278abb;
  background-color: #278abb;
}

section#pricing #pricing-btn .btn.box-premium165 {
  border-color: #6b3c94;
  background-color: #6b3c94;
}

section#pricing #pricing-btn .btn.box-premium165:hover {
  border-color: #5b436f;
  background-color: #5b436f;
}

section#pricing #pricing-btn .btn.box-premium3300 {
  background-color: #efa600;
  border: 3px solid #efa600;
  color: #fff;
  cursor: default;
}

section#pricing #pricing-btn .btn.box-premium3300:hover {
  border-color: #e8b540;
  background-color: #e8b540;
}

@media (max-width: 1199px) {
  section#pricing .box-lg .col-content {
    margin-left: 0;
  }

  section#pricing .box-lg .col-content:last-child {
    margin-right: 0;
  }
}

@media (max-width: 1023px) {
  section#pricing .box-lg {
    display: none;
  }

  section#pricing .box-md {
    display: block;
    margin-bottom: 20px;
  }
}

@media (max-width: 560px) {
  section#pricing .box-md #pricing.box .box-pricing.box-xs,
  section#pricing .box-md #pricing.box .box-detail .box-detail2.box-xs {
    display: none;
  }

  section#pricing .box-md #pricing.box .box-detail .box-pricing.box-xs {
    display: inherit !important;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  section#pricing #pricing.box .col-content {
    display: table;
    margin: 0 auto;
    text-align: center;
  }

  section#pricing .box-md #pricing.box .box-pricing .detail {
    margin: 0 auto;
  }

  section#pricing .box-md #pricing.box .box-detail {
    display: block;
    padding: 0;
  }
}

.purple-color {
  background-color: #6b3c94 !important;
  color: white;
}

.orange-color {
  background-color: #efa600 !important;
  color: white;
}

#intercom-container .intercom-app-launcher-enabled .intercom-messenger-frame {
  max-height: 65% !important;
}

#intercom-container .intercom-team-profile-full {
  padding: 18px 0 10px;
}

@media only screen and (max-width: 495px) {
  #intercom-container .intercom-app-launcher-enabled .intercom-messenger-frame {
    max-height: 100% !important;
  }
}

.headinfo {
  -webkit-transition: height 5s ease;
  -moz-transition: height 5s ease;
  -ms-transition: height 5s ease;
  -o-transition: height 5s ease;
  transition: height 5s ease;
}

.headinfo .infodiv {
  text-align: right;
  padding: 5px 30px 0 30px;
  font-size: 16px;
  margin-top: 10px;
}

.headinfo .infodiv a.linkinfo {
  display: inline-block;
  padding: 0;
  color: #2898cb;
}

.headinfo a.linkinfo.tel {
  font-size: 16px;
  line-height: 1.2em;
}

.headinfo a.linkinfo.tel b {
  font-size: 18px;
  line-height: 1.2em;
}

.headinfo a.linkinfo.tel span {
  display: inline-block;
  color: #2898cb;
}

header._showinfo .headinfo {
  height: auto;
}

header._hideinfo .headinfo {
  height: 0;
}

@media screen and (max-width: 1200px) {
  .headinfo .infodiv {
    padding: 5px 0 0 0;
  }
}

@media screen and (max-width: 990px) {
  section#banner {
    margin-top: 75px;
  }
}

@media screen and (max-width: 767px) {
  .headinfo .infodiv {
    text-align: center;
  }

  .headinfo .infodiv a.linkinfo {
    display: inline-block;
    padding: 0 5px;
  }

  section#banner {
    margin-top: 85px;
  }
}

@media screen and (max-width: 420px) {
  section#banner {
    margin-top: 87px;
  }

  .headinfo a.linkinfo.tel,
  .headinfo a.linkinfo.tel b {
    font-size: 14px;
    line-height: 1.2em;
  }
}

@media screen and (max-width: 350px) {
  .headinfo a.linkinfo.tel,
  .headinfo a.linkinfo.tel b {
    font-size: 13px;
    line-height: 1.2em;
  }

  section#pricing #pricing.box .box-pricing {
    font-size: 48px;
    line-height: 1.4em;
    font-family: 'Prompt-600', sans-serif;
  }
}

.clear-blck:after {
  content: '';
  display: block;
  clear: both;
}

.featuresdiv {
  width: 100%;
  margin: 0 auto;
}

.featuresitem {
  background: #f6f6f6;
}

.featuresitem._right {
  background: #fbfbfb;
  text-align: right;
}

.featuresitem .features_row {
  max-width: 1170px;
  width: 95%;
  margin: 0 auto;
}

.featuresitem .images,
.featuresitem .content {
  float: left;
  width: 50%;
  position: relative;
}

.featuresitem .images {
  padding: 1% 0 0 0;
}

.featuresitem._right .images {
  float: right;
  z-index: 2;
}

.featuresitem .content .cndiv {
  padding: 15% 0 5% 0;
}

.featuresitem._right .content .cndiv {
  text-align: right;
}

.featuresitem .content .head {
  font-family: rsu-regular, sans-serif;
  font-size: 28px;
  color: #0481c0;
}

.featuresitem .content .dt {
  font-size: 16px;
  color: #999;
  padding: 10px 0;
  max-width: 550px;
  width: 100%;
  float: left;
}

.featuresitem._right .content .cndiv {
  float: right;
}

.featuresitem .content .hr250 {
  margin: 20px 0;
  width: 250px;
  height: 1px;
  background: #cfcfcf;
  float: right;
}

.featuresitem._div7 .content .hr250 {
  float: left;
}

.featuresitem .content .imgapp {
  text-align: right;
}

.featuresitem._div7 .content .imgapp {
  text-align: left;
}

.featuresitem._div1 .images img {
  margin-bottom: -10%;
}

.featuresitem._div2 .images {
  margin-bottom: -3%;
}

.featuresitem._div2 .content .cndiv {
  margin-right: -20%;
}

.featuresitem._div3 .images {
  margin-bottom: -3%;
}

.featuresitem._div3 .content .cndiv {
  margin-top: 7%;
}

.featuresitem._div4 .images {
  margin-bottom: 0;
  text-align: center;
}

.featuresitem._div4 .content .cndiv {
  margin-top: 15%;
}

.featuresitem._div5 .images {
  margin-bottom: 0%;
  text-align: center;
}

.featuresitem._div5 .content .cndiv {
  margin-top: 7%;
}

.featuresitem._div6 .images {
  text-align: left;
}

.en .featuresitem._div6 .images {
  margin-top: 4%;
}

.featuresitem._div6 .content .cndiv {
  margin-top: 0;
  margin-bottom: 3%;
}

.featuresitem._div7 .images {
  text-align: right;
  padding-right: 2%;
}

.featuresitem._div7 .content .cndiv {
  margin-top: 5%;
}

@media only screen and (max-width: 1200px) {
  .featuresitem .content .head {
    font-size: 26px !important;
    line-height: 1;
  }

  .featuresitem .content .cndiv {
    padding: 10% 0;
  }

  .featuresitem._div5 .content .cndiv {
    margin-top: 15%;
  }

  .featuresitem._div6 .content .cndiv {
    margin-top: 5%;
  }

  .featuresitem._div7 .content .cndiv {
    padding: 5% 0;
  }
}

@media screen and (max-width: 767px) {
  .featuresitem .features_row {
    width: 90%;
  }

  .featuresitem .images,
  .featuresitem .content {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .featuresitem._right .images,
  .featuresitem._right .content {
    width: 100%;
    display: block;
    float: right;
    margin: 0 auto;
  }

  .featuresitem .images img {
    padding-top: 20px;
    max-width: 500px;
    width: 60%;
    text-align: center;
  }

  .featuresitem._div7 .images img {
    width: 50%;
    max-width: 400px;
  }

  .featuresitem .content .head {
    margin-top: 20px !important;
    text-align: center !important;
  }

  .featuresitem .content .hr250,
  .featuresitem._div7 .content .hr250 {
    margin: 20px auto;
    float: none;
  }

  .featuresitem .content .imgapp,
  .featuresitem._div7 .content .imgapp {
    text-align: center;
  }

  .featuresitem .content .dt {
    max-width: 100%;
    width: 100%;
    padding: 10px 2.5%;
    margin: 0;
  }

  .featuresitem._right .content .cndiv {
    text-align: center;
  }

  .featuresitem._div1 .images img,
  .featuresitem._div2 .images,
  .featuresitem._div2 .content .cndiv,
  .featuresitem._div3 .images,
  .featuresitem._div3 .content .cndiv,
  .featuresitem._div4 .images,
  .featuresitem._div4 .content .cndiv,
  .featuresitem._div5 .images,
  .featuresitem._div5 .content .cndiv,
  .featuresitem._div6 .images,
  .en .featuresitem._div6 .images,
  .featuresitem._div6 .content .cndiv,
  .featuresitem._div7 .images,
  .featuresitem._div7 .content .cndiv {
    margin: 0 auto;
    text-align: center;
  }

  .featuresitem .content .cndiv {
    padding: 0 0 5% 0;
  }

  .featuresitem .download-document {
    text-align: left;
  }
}

.features_item {
  float: left;
  width: 33.3%;
}

.features_item h3.ttl,
.features_item .img,
.features_item .dt {
  width: 90%;
  margin: 5px auto 0 auto;
}

.features_item h3.ttl,
.features_item .dt .ttl {
  color: #2898cb;
  font-size: 23px;
  text-align: center;
}

.features_item h3.ttl {
  min-height: 50px;
}

.features_item .img {
  line-height: 0;
  padding: 0 0 15px 0;
  width: 70%;
  text-align: center;
}

.features_item .dt {
  font-size: 16px;
  color: #666;
  line-height: 26px;
  min-height: 200px;
}

.features_item .dt .ttl {
  display: none;
}

@media only screen and (max-width: 900px) {
  .features_item h3.ttl,
  .features_item .dt .ttl {
    font-size: 21px;
  }

  .th .features_item h3.ttl {
    min-height: 32px;
  }

  .th .features_item .dt {
    min-height: 240px;
  }
}

@media only screen and (max-width: 767px) {
  .features_item {
    width: 95%;
    margin: 0 2.5% 15px 2.5%;
  }

  .features_item h3.ttl {
    display: none;
  }

  .features_item .dt .ttl {
    display: block;
    text-align: left;
    width: 100%;
  }

  .en .features_item h3.ttl {
    min-height: 32px;
  }

  .th .features_item .dt {
    min-height: auto !important;
  }

  .features_item .img {
    float: right;
    width: 30%;
  }

  .features_item .img img {
    width: 75%;
    margin: 0 auto;
  }

  .features_item .dt {
    float: left;
    width: 68%;
    text-align: left;
    min-height: auto !important;
  }
}

@media (max-width: 590px) {
  .features_item h3.ttl {
    display: block;
    text-align: center;
  }

  .features_item .dt .ttl {
    display: none;
  }

  .features_item .img,
  .features_item .dt {
    width: 95%;
    margin-left: 2.5%;
  }

  .features_item .img img {
    width: 70%;
    max-width: 300px;
  }
}

@media (max-width: 375px) {
  .featuresitem .content .head {
    font-size: 22vw !important;
    font-size: 6vw !important;
    line-height: 1;
  }
}

.etax-pricing-container {
  width: 100%;
  max-width: 229px;
  display: inline-block;
}

.etax-pricing-container .etax-left {
  margin-right: 10px;
}

.etax-pricing-container .etax-left img {
  margin-top: 5px;
  float: left;
  width: 50px;
}

.etax-pricing-container .etax-right {
  text-align: left;
  max-width: 150px;
  font-size: 12px;
}

.etax-pricing-container .etax-right a {
  cursor: pointer;
}

.cndiv .etax-pricing-container {
  max-width: unset;
}

#owl-function .item .display .cndiv .desc div.etax-right {
  margin-left: 25px;
  line-height: 1.5em;
  max-width: 350px;
  font-size: 14px;
  opacity: 0.8;
}

#content .itemdiv .item .cnt div.etax-pricing-container {
  width: unset;
  max-width: unset;
}

#content .itemdiv .item .cnt div.etax-left {
  width: unset;
}

#content .itemdiv .item .cnt div.etax-right {
  width: unset;
  margin-left: 25px;
  max-width: 350px;
  font-size: 14px;
  opacity: 0.8;
}

#owl-function .item .display .cndiv .desc div.etax-right a,
#content .itemdiv .item .cnt div.etax-right a {
  margin-top: 10px;
  position: relative;
  top: 5px;
}

#content .itemdiv .item .cnt.mb div.etax-right {
  max-width: unset !important;
}

@media (max-width: 1200px) {
  section#pricing .box-lg #pricing.box {
    height: 510px !important;
  }

  #content .itemdiv .item .cnt div.etax-right {
    max-width: 250px;
  }

  #owl-function .item .display .cndiv .desc div.etax-right {
    max-width: 250px;
  }
}

@media (max-width: 968px) and (min-width: 767px) {
  #content .itemdiv .item .cnt div.etax-right {
    max-width: 185px;
  }

  #owl-function .item .display .cndiv .desc div.etax-right {
    max-width: 185px;
  }
}

@media (max-width: 767px) {
  #owl-function .item .display .cndiv .desc div.etax-right {
    max-width: 100% !important;
    margin: 0;
  }
}

#content .itemdiv .item .cnt.mb div.etax-right {
  max-width: 100%;
  margin: 0;
}

#owl-function .item .display .cndiv .desc div.etax-right a,
#content .itemdiv .item .cnt div.etax-right a {
  position: unset;
}

a.blue-line,
a.blue-line:link,
a.blue-line:visited {
  color: #2898cb;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}

a.blue-line > i.fa,
a.blue-line:link > i.fa,
a.blue-line:visited > i.fa {
  background: #fff;
  color: #2898cb;
  padding: 0 5px 0 0;
  bottom: 0;
}

a.blue-line:hover,
a.blue-line:active {
  color: #1a84b5;
  text-decoration: none;
  border-bottom: 1px solid #1a84b5;
}

a.blue-line:hover > i.fa,
a.blue-line:active > i.fa {
  color: #1a84b5;
}

[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none !important;
}

.img-blur {
  filter: blur(10px);
}

.main {
  height: 50px !important;
  min-height: 50px !important;
}

.sticky-header,
.sticky-wrapper {
  position: relative;
  z-index: 1040;
}

#call_us_hoverable {
  display: none;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

a.linkwhite,
a.linkwhite:link,
a.linkwhite:visited {
  color: #fff;
}

a.linkwhite:hover,
a.linkwhite:active {
  color: #fff;
  text-decoration: underline;
}

a.footer_index,
a.footer_index:link,
a.footer_index:visited {
  color: #fff;
}

a.footer_index:hover,
a.footer_index:active {
  color: #74c3e7;
}

.modal#functionVideo,
.modal#functionLinkDocument,
.modal#functionImage {
  background-color: rgba(0, 0, 0, 0.8);

  .modal-dialog.function-function {
    max-width: 100% !important;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;

    .modal-content {
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      box-shadow: none;
      border: none;
      position: relative;
      margin-top: calc((100vh - calc((85vw * 9) / 16)) / 2);

      button.colse-btn {
        position: absolute;
        top: -50px;
        right: 0;
        color: #ccc;
        z-index: 10;
        border: none;
        outline: 0;
      }

      .modal-body {
        height: 0px;
        padding: 0;
        background-color: transparent;

        #modal-video-function {
          width: 100%;
          height: calc((85vw * 9) / 16);
          margin: 0 auto;
        }
      }
    }
  }
}

div#contactus input::-webkit-input-placeholder,
div#contactus textarea::-webkit-input-placeholder {
  color: #fff;
}

div#contactus input:-moz-placeholder,
div#contactus textarea:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

div#contactus input::-moz-placeholder,
div#contactus textarea::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

div#contactus input:-ms-input-placeholder,
div#contactus textarea:-ms-input-placeholder {
  color: #fff;
}

div#contactus .line-div .padding-line.padding-qr {
  margin-top: 65px;
  margin-bottom: 65px;

  @media (max-width: 1000px) {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  @media (max-width: 500px) {
    margin-top: 50px;
  }
}

div#contactus .line-div .image-line {
  width: 100%;
  max-width: 130px;

  img {
    height: 80px;
  }

  @media (max-width: 576px) {
    img {
      height: 120px;
    }
  }
}

.youtube-icon {
  margin-left: 25px;
}

.line-icon {
  margin-left: 30px;
}

.instagram-icon {
  margin-left: 30px;
}

.tiktok-icon {
  margin-left: 25px;
}
.medium-icon {
  padding-top: 3px;
  margin-left: 25px;
  transition: transform 0.2s;
}

.image-lineicon {
  width: 29px;

  transition: transform 0.2s;
}

.image-fbicon {
  width: 29px;

  transition: transform 0.2s;
}

.image-yticon {
  width: 29px;

  transition: transform 0.2s;
}
.image-igicon {
  width: 29px;

  transition: transform 0.2s;
}
.image-tticon {
  width: 29px;

  transition: transform 0.2s;
}

.image-lineicon:hover,
.image-fbicon:hover,
.image-yticon:hover,
.image-igicon:hover,
.image-tticon:hover,
.medium-icon:hover {
  transform: scale(1.1);
}

.downloadapp-desktop {
  display: flex;
  padding-top: 7px;
  padding-bottom: 10px;

  @media (max-width: 500px) {
    display: none;
  }
}

.downloadapp-mobile {
  display: none;

  @media (max-width: 500px) {
    display: block;
    // padding-top: 30px;
    // padding-bottom: 25px;
    display: inline-block;
    width: 140px;
    margin-left: 20px;
    // margin-top: 10px;

    .app-ios {
      max-width: 100px;
    }
  }
}

@media (max-width: 576px) {
  div#contactus .line-div .image-line {
    height: 120px;
  }
}

@media (max-width: 320px) {
  div#contactus .line-div .image-line {
    width: 100%;
    max-width: 100px;
  }
}

div#contactus {
  color: #fff;
}

div#contactus h2 {
  color: #0481c0;
  font-family: rsu-regular, sans-serif;
}

div#contactus .col {
  max-width: 490px;
}

div#contactus a i,
div#contactus a span {
  color: #fff;
}

div#contactus .contactus-heading {
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
}

div#contactus .contactus-detail {
  font-size: 16px;
  line-height: 1.67em;
  font-family: 'cs_chatthaiuiregular', sans-serif;
  padding-top: 15px;
}

.modal-dialog {
  margin-top: calc(18%);
}

@media (max-width: 350px) {
  div#contactus .contactus-heading .email {
    font-size: 23px;
  }
}

div#contactus .detail-bottom {
  width: 100%;
  font-size: 16px;
  margin-top: 30px;
  display: inline-block;
}

div#contactus .detail-bottom .detail-top {
  line-height: 23px;
}

div#contactus .detail-bottom .social {
  margin-top: 11px;
  font-size: 14px;
}

div#contactus .detail-bottom .social .ground {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
}

div#contactus .detail-bottom .detail-top {
  line-height: 23px;
}

div#contactus .detail-bottom .social {
  margin-top: 11px;
  font-size: 14px;
}

div#contactus .detail-bottom .social .ground {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
}

div#contactus .detail-bottom .detail-top {
  line-height: 23px;
}

div#contactus .detail-bottom .social {
  margin-top: 11px;
  font-size: 14px;
}

div#contactus .detail-bottom .social .ground {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
}

div#contactus .detail {
  background-color: var(--app_color);
  width: 100%;
}

div#contactus .form {
  font-size: 20px;
}

div#contactus .flow-input {
  height: 46px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 100%;
  padding: 6px 12px;
  background-color: transparent;
  font-size: 16px;
}

div#contactus .flow-input:focus {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  outline: 0;
  border: 2px solid #02ccab !important;
  outline: none !important;
}

div#contactus .flow-textarea {
  width: 100%;
  min-height: 120px;
  padding: 10px;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  resize: none;
  font-size: 16px;
  border-radius: 5px;

  @media (max-width: 1000px) {
    min-height: 160px;
  }
}

div#contactus .flow-textarea:focus {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  outline: 0;
  border: 2px solid #02ccab !important;
}

.captcha-submit {
  display: flex;
  width: 100%;

  .recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
    width: 40%;

    @media (max-width: 1100px) {
      width: 50%;
    }

    @media (max-width: 530px) {
      width: 65%;
    }

    @media (max-width: 375px) {
      transform: scale(0.67);
    }

    @media (max-width: 320px) {
      transform: scale(0.57);
    }
  }

  .row {
    width: 60%;
    margin-right: unset;
    margin-left: unset;

    @media (max-width: 1100px) {
      width: 50%;
    }

    @media (max-width: 530px) {
      width: 40%;
    }

    .col-md-12 {
      padding-right: 0px;
      padding-left: 30px;

      @media (max-width: 1100px) {
        padding-left: 0px;
      }

      .form-group {
        .flow-btn-submit {
          padding: 16.5px 10px;
          border: none;
          background-color: #fff;
          color: var(--app_color);
          width: 100%;
          font-size: 16px;
          border-radius: 5px;

          @media (max-width: 530px) {
            display: none;
          }
        }

        .flow-btn-submit_mobile {
          display: none;
          padding: 16.5px 10px;
          border: none;
          background-color: #fff;
          color: var(--app_color);
          width: 100%;
          font-size: 16px;
          border-radius: 5px;

          @media (max-width: 530px) {
            display: block !important;
          }

          @media (max-width: 375px) {
            padding: 13px 10px;
          }

          @media (max-width: 320px) {
            font-size: 15px;
            padding: 11px 10px;
          }
        }

        .flow-btn-submit_mobile[lang='en'] {
          @media (max-width: 530px) {
            font-size: 14px;
            padding: 10px;
          }

          @media (max-width: 375px) {
            padding: 6px;
          }

          @media (max-width: 320px) {
            font-size: 11px;
          }
        }
      }
    }
  }
}

.recaptcha-check {
  font-size: 15px;
  position: relative;
  top: -17px;

  @media (max-width: 375px) {
    top: -26px;
  }
}

.recaptcha-check[lang='en'] {
  font-size: 13px;

  @media (max-width: 375px) {
    font-size: 11px;
  }
}

// div#contactus .flow-btn-submit {
//   border: none;
//   background-color: #FFF;
//   color: #39c;
//   height: 46px;
//   width: 100%;
//   font-size: 18px;
//   border-radius: 2px;
//   text-transform: uppercase
// }
div#contactus .form {
  margin-bottom: 50px;
}

div#contactus .copyright p {
  font-size: 12px;
  letter-spacing: 0.7px;
}

div#contactus .social .foot-blank {
  background-color: #fff;
  height: 75px;
}

@media only screen and (max-width: 767px) {
  div#contactus .detail-bottom .social {
    padding-left: 0;
  }
}

@media (max-width: 414px) {
  div#contactus .copyright p {
    max-width: 251px;
    position: relative;
    right: 3px;
    margin: 0 auto;
  }

  div#contactus .form {
    margin-bottom: 35px;
  }
}

@media (min-width: 1010px) {
  div#contactus .col.col-detail {
    padding-right: 40px;
    padding-left: 0;
  }

  div#contactus .col.col-send {
    padding-left: 10px;
    padding-right: 0;
  }
}

@media (max-width: 1010px) {
  div#contactus .col.col-send {
    margin-top: 30px;
  }
}

.modal-dialog.function-video {
  max-width: 100% !important;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;

  .modal-content {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0;
    padding: 0;

    .modal-header {
      // padding-top: 40px;
      padding-top: 50px;

      .close {
        // right: 20px;
        right: 25px;
        top: 10px;
        color: #ccc;
        z-index: 10;
        border: none;
        outline: none;
        font-family: 'cs_chatthaiuiregular', sans-serif;

        @media screen and (max-width: 768px) and (orientation: landscape) {
          right: 5px;
          top: 0px;
        }
      }
    }

    .modal-body {
      height: 100vh;
      padding-left: 0;
      padding-right: 0;

      #videofunction,
      #videofunction2,
      #videofunction3,
      #videofunctionOverview {
        $height_video: calc((85vw * 9) / 16);
        $margin_top: calc((100vh - calc((85vw * 9) / 16)) / 2);
        width: 85vw;
        height: calc((85vw * 9) / 16);
        margin: 0 auto;
        // margin-top:calc(((100vh - calc((85vw*9)/16))/2) - 45px);
        margin-top: calc(((100vh - calc((85vw * 9) / 16)) / 2) - 65px);
      }
    }
  }
}

// footer
.working-fz {
  font-size: 16px;

  @media (max-width: 500px) {
    font-size: 14px;
  }

  .day {
    max-width: 96px;
    width: 100%;
    display: inline-block;
  }

  .day[lang='en'] {
    max-width: 70px;
  }
}

.lbl-valid {
  font-size: 0px !important;
}

.lbl-valid.hidden {
  visibility: hidden;
}

.row-footer {
  display: flex;
  border-top: 1px solid white;

  @media (max-width: 1000px) {
    display: block;
    max-width: 720px;
    margin: 0 auto;
  }

  .detail-left {
    width: 45%;
    margin-top: 60px;

    @media (max-width: 1000px) {
      width: 100%;
    }

    @media (max-width: 500px) {
      margin-top: 50px;
    }
  }

  .send-right {
    width: 50%;
    margin-left: 5%;
    margin-top: 60px;

    @media (max-width: 1000px) {
      width: 100%;
      margin-left: unset;
      margin-top: 0px;
    }
  }
}

button:focus {
  outline: 0px auto !important;
}

// ##########################

.input-border {
  display: flex;

  @media (max-width: 1000px) {
    display: block;
  }

  .name {
    width: 60%;
    margin-bottom: 0px;

    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: unset;
    }
  }

  .phone {
    width: 37%;
    margin-left: 3%;
    margin-bottom: 0rem;

    @media (max-width: 1000px) {
      width: 100%;
      margin-left: 0px;
      margin-bottom: unset;
    }
  }
}

// New style from nueng
.margin-contact-heading {
  margin-top: 10px;
}

.working-time-margin {
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 1050px) {
    margin-top: 15px;
    margin-bottom: 30px;
    display: block;
  }
}

.line-id {
  margin-left: 26px;

  @media (max-width: 1000px) {
    margin-left: 26px;
  }

  @media (max-width: 500px) {
    margin-left: 20px;
  }
}

.app-android,
.app-gallery {
  max-width: 110px;
  width: 100%;

  @media (max-width: 500px) {
    margin-top: 10px;
  }
}

// site map
.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-2dot4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 540px) {
  .col-sm-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 720px) {
  .col-md-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 960px) {
  .col-lg-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1140px) {
  .col-xl-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.function-payroll {
  margin-top: 50px;
}

.white-color {
  color: white;
}

.icon-style {
  padding-top: 7px;
  color: white;
}

.icon-style.tiktok-position {
  padding-top: 3px;
}
.social-style {
  display: flex;
  font-size: 30px;
  margin-left: 25px;
  // position: absolute;

  .youtube-position {
    // padding-left: 10px;
  }

  .line-position {
    // padding-left: 25px;
  }

  @media (max-width: 500px) {
    display: none;
  }
}

.social-style-mobile {
  display: none;
  // margin-top: 10px;

  // margin-left: 25px;
  // position: absolute;

  @media (max-width: 500px) {
    display: flex;
    font-size: 30px;
    margin-top: -5px;

    .youtube-position {
      // padding-left: 10px;
    }

    .line-position {
      // padding-left: 25px;
    }
  }
}

.iPhone-5-layout {
  @media (max-width: 320px) {
    .line-id {
      font-size: 14px !important;
    }

    .downloadapp-mobile .app-ios {
      max-width: 95px;
    }

    .app-android {
      max-width: 95px !important;
    }
  }
}

.footer_index {
  color: rgb(255, 255, 255) !important;
  display: inline-block;
  border-bottom: 1px solid transparent;
}

.footer_index:hover {
  border-bottom: 1px solid var(--system_color_white);
  text-decoration: none;
}

.has-error .form-control:focus {
  border-color: #843534 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.kbanklogo {
  max-width: 21px;
  width: 100%;
  padding-top: -17px;
  position: relative;
  top: 0;
}

// new landing from global
.container {
  // background-color: red;
  max-width: 1170px;
}

.wrapper {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
}

// new landing

.content-head {
  padding-top: 50px;

  .bordertop {
    width: 12px;
    border-top: 3px solid #2898cb;
    margin: 0 auto;
  }

  .contentname {
    font-size: 16px;
    line-height: 1.38;
    padding-top: 5px;
  }
}

.content-head2 {
  display: flex;
  line-height: 1.38;

  .borderbottom {
    width: 12px;
    border-bottom: 3px solid white;
    position: relative;
    top: -3px;
  }

  .contentname {
    color: white;
    font-size: 16px;
    padding-left: 5px;
  }
}

.new-headtext {
  font-family: 'Prompt', sans-serif;
  font-size: 26px;
  // line-height: 1.54;
  line-height: 1.38;
  max-width: 320px;
  width: 100%;
  letter-spacing: -0.2px;
  margin: 0 auto;
  padding-top: 7px;
}

.new-detailtext {
  font-size: 16px;
  line-height: 1.75;
  padding-top: 15px;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}

.view-tutorial {
  .icon {
    display: flex;

    .fa-check-circle {
      position: relative;
      top: 5px;
      color: #2898cb;
      padding-right: 10px;
    }
  }
}

.img-director {
  max-width: 60px;
  border-radius: 50%;

  @media (max-width: 420px) {
    max-width: 55px;
  }
}

.mw-kbank {
  max-width: 380px;
}

.contact-sales {
  .head {
    font-size: 20px;
    font-family: 'Prompt', sans-serif;
    line-height: 1.8;
  }

  .detail {
    padding-top: 10px;
    font-family: 'cs_chatthaiuiregular', sans-serif;
    font-size: 16px;
    color: white;

    i {
      padding-right: 14px;
      line-height: 1.42;
    }

    a {
      color: white;
    }
  }

  .detail.mobile {
    display: flex;
    width: 100%;
  }

  .detail.mobile[lang='en'] {
    display: flex;
    width: 100%;

    @media (max-width: 500px) {
      max-width: 300px;
    }
  }
}

.footer-contact-section {
  .link-to-sale-page {
    .fa-angle-right {
      position: absolute;
      padding-left: 5px;
    }

    &:hover {
      a {
        border-bottom: 1px solid white;
      }
    }
  }

  &__left-div {
    .contact-sale-container {
      .contact-sale-tablet {
        display: none;
      }

      .fa-angle-right {
        position: absolute;
        padding-left: 5px;
      }
    }

    .social-app-container {
      margin-top: 95px;
    }
  }

  &__right-div {
    width: 50%;
    margin-left: 20%;
    margin-top: 60px;

    .fa-angle-right {
      position: absolute;
      padding-left: 5px;
    }
  }

  .footer-company-address {
    padding-top: 25px;
    padding-bottom: 65px;
  }

  @media (max-width: 1083px) {
    &__right-div {
      margin-left: 9%;
    }

    .social-app-container {
      margin-top: 30px;
    }
  }

  @media (max-width: 1079px) {
    &__right-div {
      margin-left: 11%;
    }
  }

  @media (max-width: 1024px) {
    &__right-div {
      margin-left: 15%;
    }

    .social-app-container {
      margin-top: 95px;
    }
  }

  @media (max-width: 1000px) {
    &__left-div {
      .contact-sale-container {
        display: flex;
        justify-content: space-between;

        .contact-sale-tablet {
          display: block;
        }
      }

      .social-app-container {
        display: flex;
        justify-content: left;
      }
    }

    &__right-div {
      .contact-sale-desktop {
        display: none;
      }
    }

    .footer-company-address {
      text-align: left;
    }
  }

  // @media (max-width: 1024px) {
  //   &__right-div {
  //     margin-left: 15%;
  //   }
  // }

  @media (max-width: 900px) {
    &__right-div {
      margin-left: 0%;
      margin-top: 0px;
    }

    .footer-company-address {
      padding-bottom: 0px;
    }

    .social-app-container {
      margin-top: 0px;
    }
  }

  .line-icon-mobile {
    display: none;
  }

  @media (max-width: 576px) {
    &__left-div {
      .contact-sale-container {
        display: flex;
        flex-direction: column;

        .contact-sale-tablet {
          display: block;
          padding-top: 50px;
        }
      }

      .social-app-container {
        justify-content: left;
      }
    }

    &__right-div {
      width: 100%;
    }

    .footer-company-address {
      padding-top: 0px;
      text-align: left;
    }
  }

  @media (max-width: 502px) {
    .line-icon-mobile {
      display: block;
      margin-top: -10px;

      img {
        height: 40px;
      }
    }
  }

  .app-btn-wrapper {
    display: flex !important;
    justify-content: center;
    align-items: center;

    .line-add-friend {
      // margin-left: 25px;
      margin-top: 5px;
      margin-right: 25px;

      img {
        height: 30px;
      }
    }

    @media (max-width: 587px) {
      .line-add-friend {
        margin-left: 0px;
        margin-top: 0px;
      }
    }
  }
}

.ml-30 {
  margin-left: 30px;
}

.mb-8 {
  margin-bottom: 8px;
}

.u-text-center {
  text-align: center;
}

.social-icon-mobile {
  display: none;
  @media (max-width: 578px) {
    display: block;
    margin-top: 10px;
    margin-bottom: -20px;
    .social-wrapper {
      display: flex;
      .fa-facebook-square,
      .fa-youtube,
      .fa-instagram {
        font-size: 35px;
      }
      .fa-instagram {
        padding-left: 5px;
      }
      .medium-icon {
        padding-top: 13px;
        margin-left: 30px;
      }
      .tiktok-svg-icon {
        height: 50px;
      }
      .icon-style.tiktok-position {
        padding-top: 0px;
        padding-left: 10px;
      }
    }
  }
}

.margin-left-5 {
  margin-left: 5px;
}
.mt-minus-5 {
  margin-top: -5px;
}

.visuallyhidden {
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
}

.payroll-italic {
  color: #06b189;
  font-style: italic;
}
.autokey-italic {
  color: #ff849f;
  font-style: italic;
}
.mobilepos-italic {
  color: #9a99ff;
  font-style: italic;
}

.fa-hover-line-white {
  &:hover {
    border-bottom: 1px solid white;
  }
}

.skeleton-loader {
  transform: translateZ(0);
  position: relative;
  background: #eff1f6 no-repeat;
  border-radius: 4px;
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  will-change: transform;
  &::before {
    content: '';
    background-image: linear-gradient(90deg, #fff0, #fff9, #fff0);
    animation: animation 2s ease-in-out infinite;
    background-size: 200px 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 200px;
    height: 100%;
  }
  @keyframes animation {
    0% {
      transform: translate3d(-200px, 0, 0);
    }
    100% {
      transform: translate3d(calc(200px + 100vw), 0, 0);
    }
  }
}
