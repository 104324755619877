/* เซตสีแอฟ สีที่ใช้กลางๆ ตรงนี้ */
:root {
  //color
  --system_color_text: #353d48;
  --system_color_white: #ffffff;
  // //menu
  // --color_menu: #526175;
  // --color_menu2: #485567;
  // //fonts
  // --font-prompt: 'Prompt';
  // --font-chatthai: 'cs_chatthaiuiregular';

  /* app color: ตั้งค่าเริ่มต้นตามสีหลักก่อนสำหรับ flowaccount */
  --app_color: #2898cb;
  --app_color_hover: #1a84b5;
  --app_btn: #66bd00;
  --app_color_register_text: #fff;
  --app_color_register_btn: #66bd00;
  --color_gray64: #646e7a;
  --media_menu_ipad: 990px;
  --media_menu_mobile: 480px;
  --color_gray55: #555;
  --color_green: #88c426;
  --color_gray7f: #7f96a7;
  --color_green_hover: #66bd00;
  --color_flow_09: rgba(40, 152, 203, 0.9);
  --color_gray97: #979797;
  --color_gray46: #464646;
}

/* ถ้ัามี landing ใหม่มาเพิ่มสีแอฟตรงนี้ */

/* flowaccount ไม่ต้องเขียนเพราะเป็นค่าเริ่มต้น */
// :root {
//     .flowaccount-landing{}
// }

/* payroll กำหนดสีตัวเอง */
:root {
  .payroll-landing {
    --app_color: #06b189;
    --app_color_hover: #009d78;
    --app_btn: #06b189;
    --app_color_register_text: #06b189;
    --app_color_register_btn: #ffffff;
  }
}

// for FAQ =================
.faq-link {
  color: var(--app_color);
}
.faq-link:active,
.faq-link:hover {
  color: var(--app_color);
  border-bottom: 1px solid var(--app_color);
}
.homepage-faq1 {
  max-width: 530px;
}
.homepage-faq2 {
  max-width: 520px;
  @media (max-width: 768px) {
    max-width: 650px;
  }
  @media (max-width: 375px) {
    max-width: 335px;
  }
}
.homepage-faq2[lang="en"] {
  max-width: 515px;
}
.homepage-faq5 {
  max-width: 630px;
}
.homepage-faq6 {
  @media (max-width: 375px) {
    max-width: 315px;
  }
}
.pricingpage-faq2 {
  max-width: 512px;
  @media (max-width: 768px) {
    max-width: unset;
  }
}
.pricingpage-faq2[lang="en"] {
  max-width: unset;
}
.pricingpage-faq3 {
  max-width: 530px;
  @media (max-width: 768px) {
    max-width: unset;
  }
}
.pricingpage-faq3[lang="en"] {
  max-width: 510px;
  @media (max-width: 768px) {
    max-width: unset;
  }
}
.pricingpage-faq4 {
  max-width: 540px;
  @media (max-width: 768px) {
    max-width: unset;
  }
}
.pricingpage-faq4[lang="en"] {
  @media (max-width: 768px) {
    max-width: unset;
  }
}
.pricingpage-faq5[lang="en"] {
  max-width: 530px;
  @media (max-width: 768px) {
    max-width: unset;
  }
}
.pricingpage-faq6[lang="en"] {
  @media (max-width: 768px) {
    max-width: 630px;
  }
}
.pricepage {
  display: block;
  margin-top: 100px;
  .container {
    margin-bottom: 0px !important;
  }
}
// =========================
.hover-underline-text {
  color: #2898cb;
  position: relative;
  text-decoration: none;
}
.hover-underline-text:hover {
  color: #2898cb;
}
.hover-underline-text::after {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 0%;
  border-bottom: 1px solid #2898cb;
  left: 0;
  transition: width 0.3s ease-in-out;
}
.hover-underline-text:hover::after {
  width: 100%;
  transition: all 0.3s;
}
