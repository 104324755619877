@import './core-variables.scss';

//ต่อไปมี new feature ใน landing ให้ใส่ class นี้ในตารางราคาเพื่อกำหนด tag ใหม่
.new-feature {
  background-color: #fea000;
  color: white;
  font-size: 14px;
  text-align: center;
  max-width: 45px;
  width: 100%;
  border-radius: 5px;
  margin-left: 7px;
  height: 4px;
  padding: 0px 10px;
  font-family: $titlefont;
}

.stripe-pricing-logo {
  width: 35px;
  height: auto;
}

/* 1.flowaccount-list-menu */
#flowaccount-list-menu {
  float: right;
  width: 100%;

  @media (max-width: $media_menu_ipad) {
    // display: none; __<<ngThemingMigrationEscapedComment1>>__
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background: var(--system_color_white);
    width: 385px;
    height: calc(100vh + 100px);
    position: absolute;
    margin: -100px 0 0 0px;
    padding-top: 145px;
    z-index: 1002;
    right: -14px;
    top: 0;
    overflow-y: auto;
    transform-origin: 0% 0%;
    transform: translate(105%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  @media (max-width: $media_menu_mobile) {
    // background: var(--system_color_white);
    width: 100vw;
    //  height: calc(100vh - #{$menu_min_heightMb});
    //  top: $menu_min_heightMb;
    padding-top: 0px;
    margin-top: 35px;
  }
}

/* 1.1 */
#flow-menu-div {
  float: left;
}

/* 1.2 */
#flow-button-div {
  float: right;

  a:not(:first-child) {
    margin-left: 14px;
  }

  @media (max-width: 1250px) {
    //สำหรับย่อจอเมนู
    a {
      min-width: 100px;
    }
  }

  @media (max-width: $media_menu_ipad) {
    width: 100%;
    text-align: center;
    padding: 0 25px; ///padding_menu_ipad

    a {
      width: 100%;
      max-width: 335px;
      margin: 0 auto 20px auto;
      height: 45px;
      line-height: 43px;
      font-size: 18px;
    }

    a:not(:first-child) {
      margin-left: 0;
    }
  }

  @media (max-width: $media_menu_mobile) {
    margin: 0 auto;
    width: 100%;
    padding: 0 20px; //padding_menu_mb

    a {
      max-width: 100%;
    }
  }
}
.showres-navdisplay {
  display: none !important;
  @media (max-width: 1100px) {
    display: flex !important;
  }
}

/* 2.flowaccount-bg-ipad */
#flowaccount-bg-ipad {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1001;
  background: #000;
  opacity: 0.4;
  top: 0;
  left: 0;

  @media (max-width: $media_menu_ipad) {
    display: none;
    /* เริ่มต้นให้ปิดก่อน */
  }

  @media (max-width: $media_menu_mobile) {
    display: none;
  }
}

/* main : กรณีกดเปิด hamburger */
#flowaccount-menu.open {
  @media (max-width: $media_menu_ipad) {
    .hamburger-div {
      position: relative;
      z-index: 1003;
      top: 0;
      right: 0; //25px
      margin: 0;
    }
  }

  #flowaccount-list-menu {
    @media (max-width: $media_menu_ipad) {
      display: block;
      padding-top: 104px;
    }

    @media (max-width: $media_menu_mobile) {
      // padding-top: 0px;
      // margin-top: 35px;
    }
  }

  #flowaccount-bg-ipad {
    @media (max-width: $media_menu_ipad) {
      display: block;
      cursor: pointer;
    }

    @media (max-width: $media_menu_mobile) {
      display: none;
    }
  }
}

/* end - main : กรณีกดเปิด hamburger */
/*
$media_menu_ipad: 990px;
$media_menu_mobile: 590px;*/

.new {
  background-color: #fea000;
  color: #fff;
  font-size: 13px;
  text-align: center;
  max-width: 37px;
  width: 100%;
  border-radius: 5px;
  height: 22px;
  padding: 3px 6px;
  font-family: 'cs_chatthaiuiregular', sans-serif;
}

.tag-margin-custom {
  margin-left: 7px;

  @media (max-width: 365px) {
    & {
      margin-left: 2px;
    }
  }
}

.nav-scroll-menu {
  height: 500px;
  overflow-y: scroll;

  a {
    max-width: 335px;
  }

  .custom-margin-bottom {
    margin-bottom: 20px !important;
  }

  @media (max-width: 1100px) {
    height: auto;
    overflow-y: auto;

    a {
      max-width: unset;
    }

    .custom-margin-bottom {
      margin-bottom: unset !important;
    }
  }
}
