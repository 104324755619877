@import './core-variables.scss';

@mixin link1() {
  //*
  @include fontstyle($bodyfont, 18px, normal, normal, var(--color_gray55));

  @media (max-width: $min_width_mb) {
    font-weight: 500;
    line-height: normal;
  }
}

@mixin marginMenu() {
  margin: 0 13.5px;
  margin: 0 7px;

  @media (max-width: 1245px) {
    margin: 0 6px;
  }
}

@mixin boxshadowMore() {
  -webkit-box-shadow: 8px 9px 32px 0 rgba(0, 0, 0, 0.11) !important;
  -moz-box-shadow: 8px 9px 32px 0 rgba(0, 0, 0, 0.11) !important;
  box-shadow: 8px 9px 32px 0 rgba(0, 0, 0, 0.11) !important;
}

@mixin boxshadowMenuMb() {
  -webkit-box-shadow: 2px 12px 11px 0 rgba(0, 0, 0, 0.26) !important;
  -moz-box-shadow: 2px 12px 11px 0 rgba(0, 0, 0, 0.26) !important;
  box-shadow: 2px 12px 11px 0 rgba(0, 0, 0, 0.26) !important;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

body.open-menu {
  overflow-x: hidden;
}

//======================================================================
/* menu-section */
section.menu-section.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

section.menu-section {
  min-height: $menu_min_height;
  z-index: 99;
  background-color: var(--system_color_white);
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0; //give 23.03.20
  left: 0; //give 23.03.20

  .wrapper {
    max-width: 1300px !important;
    margin: 0 auto;
  }

  @media (max-width: $media_menu_ipad) {
    min-height: $menu_min_heightIpad;
  }

  @media (max-width: $media_menu_mobile) {
    min-height: $menu_min_heightMb;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.03);
  }
}

.download-app-tab {
  display: none;

  @media (max-width: $media_menu_mobile) {
    display: block;
  }
}

/* menu-support */
.menu-support {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;

  a.suport-contact {
    float: right;
    @include link1();
    padding: 11.5px 20px;
    text-align: center;
    background: var(--system_color_white);
    box-shadow: 2px 2px 10px 0 rgba(6, 6, 6, 0.14);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    > i {
      margin-right: 10px;
      transform: rotate(-45deg);
    }
  }

  .language-icon {
    picture {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 25px;
    }

    @media (max-width: $media_menu_ipad) {
      margin-left: 12px;
    }

    @media (max-width: $media_menu_mobile) {
      margin-left: 10px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: $media_menu_ipad) {
    margin-bottom: 7.5px;

    a.suport-contact {
      padding-left: 0;
      padding-right: 0;
      box-shadow: none;
    }
  }

  @media (max-width: $media_menu_mobile) {
    display: flex;
    align-items: center;
    margin-bottom: 7.5px;

    a.suport-contact {
      font-size: 3.5vw;
      display: block;
      padding-left: 0;
    }
  }
}

/* menu-flowaccount */
#menu-flowaccount {
  min-height: $menu_flowaccount;

  /* flowaccount-logo */
  #flowaccount-logo {
    float: left;

    a {
      display: block;
      height: 30px;
      padding: calc(($menu-flowaccount - 30px) / 2) 0;

      img {
        width: auto;
        height: 28px;
      }
    }

    @media (max-width: $media_menu_ipad) {
      a {
        height: 26px;
        padding: calc(($menu-flowaccount - 26px) / 2) 0;

        img {
          height: 26px;
        }
      }
    }

    @media (max-width: $media_menu_mobile) {
      //ต้องทำเพราะอยากได้เงาตอนเปิดเมนู mb
      // width: calc(100% + 30px);
      // height: 55px;
      // margin: 0 -15px;
      // padding: 0 15px;
      // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.03);
      //end - ต้องทำเพราะอยากได้เงาตอนเปิดเมนู mb
    }
  }

  /* flowaccount-menu */
  #flowaccount-menu {
    float: right;

    .hamburger-div {
      .hamburger-icon {
        display: none;

        @media (max-width: $media_menu_ipad) {
          display: block;
        }
      }

      @media (max-width: $media_menu_ipad) {
        float: right;
        line-height: 20px;
        margin: 12.5px 0 0 0;
        position: relative;
      }

      @media (max-width: $media_menu_mobile) {
        //ต้องทำเพราะอยากได้เงาตอนเปิดเมนู mb
        // clear: both;
        // position: fixed;
        // z-index: 1001;
        // top: 48.5px + 10px + 2px;
        // right: 15px;
        // margin: 0;
        //end - ต้องทำเพราะอยากได้เงาตอนเปิดเมนู mb
      }
    }
  }

  //ตามขนาดโลโก้ของแต่ละ app
  #flowaccount-menu.flowaccount-landing {
    width: calc(100% - 230px);
  }

  #flowaccount-menu.payroll-landing {
    width: calc(100% - 218px);
  }

  /* end - flowaccount-menu */
}

//======================================================================
//======================================================================
//======================================================================
.wrappermenubarXXX {
  padding: 0 15px 0 15px;
}

.language-iconXXX {
  padding-left: 15px;
  padding-top: 10px;

  img {
    width: 24px;
  }

  @media (max-width: 420px) {
    padding-left: 15px;
    padding-top: 5px;

    img {
      width: 20px;
    }
  }
}

section.menu-sectionXXX.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

section.menu-sectionXXX {
  // min-height: $menu_min_height;
  // z-index:9;
  // position: relative;
  // background-color: #fff;
  // width: 100%;
  // box-shadow: 2px 0 3px 0 rgba(6, 6, 6, 0.14);
  min-height: $menu_min_height;
  z-index: 9;
  background-color: #fff;
  width: 100%;
  box-shadow: 2px 0 3px 0 rgba(6, 6, 6, 0.14);
  position: fixed;

  // top: 0;
  .menu-support {
    width: 100%;
    display: block;

    > a {
      @include link1();
      padding: 13px 28px;
      text-align: center;
      float: right;
      background: var(--system_color_white);
      box-shadow: 2px 2px 10px 0 rgba(6, 6, 6, 0.14);
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      > i {
        margin-right: 10px;
      }
    }
  }

  section.menu-logo {
    width: 30%;
    float: left;
    // max-width: 226px;
    max-width: 294px;
    line-height: 30px;
    // padding: (($menu_min_height - 30px)/2) 0px;
    padding: 26px 0;
  }

  section.menu-navigate {
    width: 70%;
    float: right;
    line-height: 42px;
    padding: calc(($menu-min-height - 42px) / 2) 0px;

    .hamburger-div .hamburger-icon {
      display: none;
    }

    ul.ul-navigate {
      @include boxshadowNone();
      // display: block; //for init
      //float: right;
      text-align: right;
      padding: 0;
      margin: 0;
      z-index: 100;

      > li {
        display: inline-block;
        //float: left;
        text-align: left;
        list-style: none;
        margin: 0 3px;
      }

      > li.menu-link {
        span.icon-pc {
          display: inline-block;
        }

        span.icon-mb {
          display: none;
        }

        a.a-menu,
        a.a-menu:link,
        a.a-menu:visited {
          color: var(--color_gray55);
          text-decoration: none;
          @include marginMenu();
          @include link1();

          i {
            font-size: 15px;
            position: relative;
            bottom: -1px;
            padding-left: 5px;
          }

          span.hr {
            border-bottom: 1px solid transparent;
          }
        }

        a.a-menu:hover,
        a.a-menu:active {
          span.hr {
            border-bottom: 1px solid var(--color_gray55);
          }
        }

        a.a-menu.current,
        a.a-menu.current:link,
        a.a-menu.current:visited,
        a.a-menu.current:hover,
        a.a-menu.current:active {
          color: var(--app_color);

          span.hr {
            border-bottom: 1px solid var(--app_color);
          }
        }
      }

      > li.menu-link.menu-link-more {
        position: relative;

        .more-group {
          position: absolute;
          z-index: 2;
          top: 43px;
          width: auto;
          // min-width: 160px;
          min-width: 260px;
          text-align: left;

          .more-group-shadow {
            background: #fff;
            padding: 8px 4px;
            @include boxshadowMore();
            @include border-radius(6px);

            a.a-menu-more,
            a.a-menu-more:link,
            a.a-menu-more:visited {
              display: block;
              line-height: 1.27em;
              padding: 8px 0;

              span.hr {
                border-bottom: 1px solid transparent;
                padding-bottom: 1px;
                padding-left: 0px;
                margin-left: 2px;
                @include transitionFunction(all 0.3s ease);
              }
            }

            a.a-menu-more:hover,
            a.a-menu-more:active {
              span.hr {
                border-bottom: 1px solid var(--app_color);
                color: var(--app_color);
              }
            }

            a.a-menu-more.current,
            a.a-menu-more.current:link,
            a.a-menu-more.current:visited,
            a.a-menu-more.current:hover,
            a.a-menu-more.current:active {
              color: var(--app_color);

              span.hr {
                border-bottom: 1px solid var(--app_color);
              }
            }
          }
        }

        .more-group.en {
          min-width: 260px;
        }

        .more-group.partner {
          position: absolute;
          z-index: 1;
          top: 43px;
          width: auto;
          min-width: 240px;
          text-align: left;

          .more-group-shadow {
            background: #fff;
            padding: 8px 4px;
            @include boxshadowMore();
            @include border-radius(6px);

            a.a-menu-more,
            a.a-menu-more:link,
            a.a-menu-more:visited {
              display: block;
              line-height: 1.27em;
              padding: 8px 0;

              span.hr {
                border-bottom: 1px solid transparent;
                padding-bottom: 1px;
                @include transitionFunction(all 0.3s ease);
              }
            }

            a.a-menu-more:hover,
            a.a-menu-more:active {
              span.hr {
                border-bottom: 1px solid var(--app_color);
                color: var(--app_color);
              }
            }

            a.a-menu-more.current,
            a.a-menu-more.current:link,
            a.a-menu-more.current:visited,
            a.a-menu-more.current:hover,
            a.a-menu-more.current:active {
              color: var(--app_color);

              span.hr {
                border-bottom: 1px solid var(--app_color);
              }
            }
          }
        }

        .more-group.morefunctions {
          min-width: 360px;
        }

        .more-group.morefunctions.en {
          position: absolute;
          z-index: 1;
          top: 43px;
          width: auto;
          min-width: 360px;
          text-align: left;

          .more-group-shadow {
            background: #fff;
            padding: 8px 4px;
            @include boxshadowMore();
            @include border-radius(6px);

            a.a-menu-more,
            a.a-menu-more:link,
            a.a-menu-more:visited {
              display: block;
              line-height: 1.27em;
              padding: 8px 0;

              span.hr {
                border-bottom: 1px solid transparent;
                padding-bottom: 1px;
                @include transitionFunction(all 0.3s ease);
              }
            }

            a.a-menu-more:hover,
            a.a-menu-more:active {
              span.hr {
                border-bottom: 1px solid var(--app_color);
                color: var(--app_color);
              }
            }

            a.a-menu-more.current,
            a.a-menu-more.current:link,
            a.a-menu-more.current:visited,
            a.a-menu-more.current:hover,
            a.a-menu-more.current:active {
              color: var(--app_color);

              span.hr {
                border-bottom: 1px solid var(--app_color);
              }
            }
          }
        }
      }

      > li.menu-btn {
        @include marginMenu();

        a.a-menu {
          width: 150px;
          text-align: center;
          display: block;
        }
      }
    }

    // ul.ul-navigate.en{
    //     > li.menu-link {
    //         a.a-menu:link{
    //             font-size: 16px;
    //             line-height: normal;
    //         }
    //     }
    // }
  }

  @media (max-width: 990px) {
    section.menu-navigate {
      ul.ul-navigate {
        > li.menu-link {
          a.a-menu,
          a.a-menu:link,
          a.a-menu:visited {
            font-size: 15px;
            font-size: 2.05vw;
            margin: 0 0.3vw;
          }
        }

        > li.menu-btn {
          a.a-menu {
            min-width: auto;
            padding: 8px;
            font-size: 15px;
            font-size: 2.05vw;
          }
        }
      }
    }
  }

  @media (max-width: $min_width_menu) {
    min-height: $menu_min_height_mb;

    .menu-support {
      > a {
        width: 100%;
        clear: both;
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        text-align: right;
        padding-right: 0;
      }
    }

    section.menu-logo {
      width: 90%;
      max-width: 226px;
      float: left;
      line-height: 30px;
      padding: calc(($menu-min-height-mb - 30px) / 2) 0px;
    }

    section.menu-navigate {
      width: 5%;
      min-width: 20px;
      float: right;
      line-height: 42px;
      padding: calc(($menu-min-height-mb - 42px) / 2) 0px;

      @media (max-width: 375px) {
        padding: 8px 0px;
      }

      .hamburger-div {
        float: right;
        display: block;
        line-height: 20px;
        margin: 17px 0 0 0;
        position: relative;
      }

      .morefunctions {
        display: block !important;
      }

      ul.ul-navigate {
        @include boxshadowMenuMb();
        // display: none; //for init
        // opacity: 0;
        opacity: 0;
        display: none;
        position: absolute;
        width: 100%;
        top: $menu_min_height_mb + 40px; //tel
        left: 0;
        background: var(--system_color_white);
        padding: 16px 18px;

        > li {
          display: block;
        }

        > li.menu-link {
          span.icon-pc {
            display: none;
          }

          span.icon-mb {
            display: inline-block;
            position: absolute;
            right: 15px;
          }

          > a {
            width: 100%;
            display: inline-block;
            @include link1();
          }

          a.a-menu,
          a.a-menu:link,
          a.a-menu:visited {
            margin: 0;
            @include link1();
          }

          a.green,
          a.green:link,
          a.green:visited,
          a.green:hover,
          a.green:active {
            color: var(--color_green);
          }

          a.blue,
          a.blue:link,
          a.blue:visited,
          a.blue:hover,
          a.blue:active {
            color: var(--app_color);
          }

          a.red,
          a.red:link,
          a.red:visited,
          a.red:hover,
          a.red:active {
            color: #ff0000;
          }
        }

        > li.menu-link.menu-link-more {
          .more-group {
            position: relative;
            top: 0;
            right: 0;
            text-align: left;

            .more-group-shadow {
              padding: 0 0 0 15px;
              margin-top: 0;
              @include boxshadowNone();
              @include border-radius(0);
            }

            .more-group-shadow[lang='en'] {
              padding: 0 0 0 15px;
            }
          }

          .more-group.partner {
            position: relative;
            top: 0;
            right: 0;
            text-align: left;

            .more-group-shadow {
              padding: 0 0 0 15px;
              margin-top: 0;
              @include boxshadowNone();
              @include border-radius(0);
            }
          }

          .more-group.morefunctions.en {
            position: relative;
            top: 0;
            right: 0;
            text-align: left;

            .more-group-shadow {
              padding: 0 0 0 25px;
              margin-top: 0;
              @include boxshadowNone();
              @include border-radius(0);
            }
          }
        }
      }
    }

    section.menu-navigate.open {
      ul.ul-navigate {
        opacity: 1 !important;
        display: block;

        @media (max-width: 420px) {
          // max-height: 406px;
          max-height: 480px;
          overflow-y: scroll;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .menu-support {
      > a {
        // font-size:15px;
        font-size: 3.5vw;
        display: block;
        padding-left: 0;
      }
    }
  }

  // @media (max-width: 380px){
  //     .menu-support{
  //         > a{
  //             font-size:11px;
  //         }
  //     }
  //     .menu-support[lang="en"]{
  //         > a{
  //             font-size:13px;
  //         }
  //     }
  // }
}

section.menu-sectionXXX.en {
  min-width: 240px;
}

//for about
section.menu-sectionXXX._v_for_more {
  .wrapper {
    max-width: 1300px !important;
  }

  @media (max-width: 1230px) {
    section.menu-navigate {
      ul.ul-navigate {
        > li.menu-link {
          a.a-menu,
          a.a-menu:link,
          a.a-menu:visited {
            margin: 0 0.4vw;
          }
        }

        > li.menu-btn {
          margin: 0 0.4vw;

          a.a-menu {
            min-width: auto;
            width: unset;
          }
        }

        > li.menu-btn.login {
          margin: 0 0px 0 7px;
        }
      }
    }
  }

  @media (max-width: 1100px) {
    section.menu-navigate {
      ul.ul-navigate {
        > li.menu-link {
          a.a-menu,
          a.a-menu:link,
          a.a-menu:visited {
            font-size: 16px;
          }
        }

        > li.menu-btn {
          a.a-menu {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    section.menu-navigate {
      ul.ul-navigate {
        > li.menu-link {
          a.a-menu,
          a.a-menu:link,
          a.a-menu:visited {
            font-size: 1.6vw;
          }
        }

        > li.menu-btn {
          a.a-menu {
            font-size: 1.6vw;
            width: unset;
          }
        }
      }
    }
  }

  @media (max-width: $min_width_menu) {
    section.menu-navigate {
      ul.ul-navigate {
        width: 53%;
        left: unset;
        right: 0;
        min-height: 1000px;
        top: 111px;

        @media (max-width: 500px) {
          width: 100%;
          left: unset;
          right: 0;
          min-height: unset;
          top: 107px;
        }

        @media (max-width: 375px) {
          top: 105px;
        }

        > li.menu-link {
          a.a-menu,
          a.a-menu:link,
          a.a-menu:visited {
            font-size: 18px;
          }
        }

        > li.menu-btn {
          a.a-menu {
            font-size: 18px;
          }
        }
      }
    }

    section.menu-navigate.open {
      ul.ul-navigate {
        > li.menu-btn.action {
          max-width: 280px;
          margin-top: 10px;

          @media (max-width: 900px) {
            display: none;
          }

          @media (max-width: 500px) {
            display: block;
            max-width: unset;
          }
        }

        > li.menu-link {
          max-width: 290px;

          @media (max-width: 900px) {
            max-width: unset;
          }
        }

        > li.menu-btn.login {
          margin: 10px 0.4vw;

          @media (max-width: 500px) {
            max-width: unset;
          }
        }
      }
    }
  }
}

.displayFlexXXX {
  display: flex;
  padding: 0 10px;

  .fa-circle {
    position: relative;
    top: 15px;
    font-size: 7px;
  }

  .hr {
    padding-left: 3px;
  }
}

.foripadXXX {
  display: none;

  @media (max-width: 900px) {
    display: block;
    bottom: 20px;
    width: 45%;
    position: fixed;
  }

  @media (max-width: 500px) {
    display: none;
  }

  li {
    display: inline-block;
    text-align: left;
    list-style: none;
    margin: 0 7px;

    @media (max-width: 900px) {
      display: block;
      margin: 0 0 10px;
    }

    a {
      width: 150px;
      text-align: center;
      display: block;

      @media (max-width: 900px) {
        width: unset;
      }
    }
  }
}

//======================================================================
//======================================================================
//======================================================================
