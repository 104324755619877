@import 'core-variables.scss';

.cover-page {
  //  height:calc(100vh - 138px);
  //  background-image: url('https://flowaccountcdn.com/assets/image/cover-page/โปรแกรมบัญชี_flowaccount_special_desktop@2x.jpg');
  //  background-size: cover;
  //  background-position: center center;
  background-color: #2898cb;
  .newtext {
    font-family: 'Prompt', sans-serif;
    color: white;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    padding: 26px 0;
    .link-readmore {
      color: white;
      text-decoration: none;
    }
    i {
      font-size: 13px;
    }
    span.hr:link,
    span.hr:visited {
      border-bottom: 1px solid transparent;
      color: white;
    }
    span.hr:hover,
    span.hr:active {
      border-bottom: 1px solid white !important;
    }
  }
  .newtext.ipad,
  .newtext.mobile {
    display: none;
  }
  @media (max-width: 830px) {
    background-image: url('https://flowaccountcdn.com/assets/image/cover-page/โปรแกรมบัญชี_newflowacount_Special_ipad_new.jpg');
    background-size: cover;
    background-position: center center;
    height: 198px;
    .newtext.desktop {
      display: none;
    }
    .newtext.ipad {
      display: block;
      max-width: 620px;
      width: 95%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: unset;
      .readmore {
        padding-top: 35px;
        font-family: 'cs_chatthaiuiregular', sans-serif;
        font-size: 16px;
        i {
          font-size: 13px;
        }
      }
    }
  }
  @media (max-width: 420px) {
    height: 240px;
    .newtext.desktop,
    .newtext.ipad {
      display: none;
    }
    .newtext.mobile {
      display: block;
      max-width: 350px;
      width: 95%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: unset;
      .readmore {
        padding-top: 25px;
        font-family: 'cs_chatthaiuiregular', sans-serif;
        font-size: 14px;
        i {
          font-size: 10px;
        }
      }
    }
  }
  // .text-cover{
  //    position: absolute;
  //    top: 50%;
  //    left: 50%;
  //    transform: translate(-50%, -50%);
  //    text-align: center;
  //    margin: 0 auto;
  //    max-width: 1400px;
  //    width: 90%;
  //    .line-1{
  //       @include fontstyle($titlefont_bold,40px,normal,1.47em,var(--system_color_white));
  //       padding: 15px 0;
  //    }
  //    .line-2{
  //       @include fontstyle($titlefont_bold,24px,normal,1.47em,var(--system_color_white));
  //    }
  //    hr{
  //       display: none;
  //    }
  //    .line-3{
  //       @include fontstyle($bodyfont,18px,normal,1.47em,var(--system_color_white));
  //       padding: 10px 0;
  //       max-width: 591px;
  //       width: 100%;
  //       margin: auto
  //    }
  //    .line-4{
  //       @include fontstyle($titlefont_bold,24px,normal,1.47em,var(--system_color_white));
  //       padding-top: 10px;
  //    }
  //    .line-5{
  //       display: block;
  //    }
  //    .line-6{
  //       display: none;
  //    }
  //    .logo{
  //       display: table;
  //       margin: 20px auto;
  //       width: 100%;
  //       max-width: 1000px;
  //       img {
  //          vertical-align: unset;
  //          border-style: unset;

  //       }
  //       .change-logo{
  //             width: 90%;
  //             margin: 0 auto;
  //          }
  //    }
  //    @media (max-width:830px){
  //          max-width: 700px;
  //          .line-1{
  //             font-size: 30px
  //          }
  //          .line-2{
  //             font-size: 20px
  //          }
  //          hr{
  //             display: none;
  //          }
  //          .line-4{
  //             font-size: 20px
  //          }
  //          .line-5{
  //             display: block;
  //             @include fontstyle($bodyfont,16px,normal,1.47em,var(--system_color_white));
  //          }
  //          .line-6{
  //             display: none;
  //          }
  //    }
  //    @media (max-width:600px){
  //       .line-1{
  //          font-size: 5vw;
  //       }
  //       .line-2{
  //             font-size: 3.2vw;
  //       }
  //       .line-3{
  //             font-size: 3vw;
  //       }
  //       .line-4{
  //             font-size: 3.2vw;
  //             padding-top: 10px;
  //       }
  //       .logo{
  //          margin: 10px auto;
  //       }
  //    }
  //    @media (max-width:420px){
  //          max-width: 400px;
  //          .line-1{
  //             font-size: 30px
  //          }
  //          .line-2{
  //             font-size: 14px;
  //             max-width: 230px;
  //             margin: 0 auto;
  //          }
  //          hr{
  //             display: block;
  //             max-width: 175px;
  //             margin: 30px auto;
  //             border-top: 1px solid #eee;
  //             opacity: 0.65;
  //          }
  //          .line-3{
  //             display: none;
  //          }
  //          .line-4{
  //             font-size: 14px;
  //             max-width: 200px;
  //             margin: 0 auto;
  //             padding-top: 0px;
  //          }
  //          .line-5{
  //             display: none;
  //          }
  //          .line-6{
  //             display: block;
  //             @include fontstyle($bodyfont,16px,normal,1.47em,var(--system_color_white));
  //             padding-top: 50px;
  //          }
  //          .logo{
  //             display: none;
  //          }
  //    }
  //    @media (max-width:320px){
  //       .line-1{
  //          font-size: 26px;
  //       }
  //    }

  // }
}
.text-mobile {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .line-1 {
    @include fontstyle($titlefont_bold, 30px, normal, 1.47em, var(--system_color_white));
    padding-bottom: 15px;
    width: 700px;
    @media (max-width: 420px) {
      width: 320px;
    }
    @media (max-width: 420px) {
      font-size: 28px;
    }
  }
  .line-2 {
    @include fontstyle($titlefont, 14px, normal, 1.47em, var(--system_color_white));
    max-width: 400px;
    margin: 0 auto;
    @media (max-width: 420px) {
      width: 230px;
    }
  }
  .line-6 {
    @include fontstyle($bodyfont, 16px, normal, 1.47em, var(--system_color_white));
    padding-top: 20px;
  }
}
.download-app-tab {
  /* display: none; */
  background-color: #f6f7f8;
  /* display: table; */
  width: 100%;
  padding: 15px 15px;
  font-family: 'cs_chatthaiuiregular', sans-serif;
  font-size: 12px;
  line-height: 1.42;
  text-align: center;
  .pull-left {
    float: left !important;
    .app-close {
      display: inline-block;
      vertical-align: middle;
      color: #2898cb;
      font-size: 14px;
    }
    .app-logo {
      display: inline-block;
      vertical-align: middle;
      margin: 0 12px;
      @media (max-width: 360px) {
        margin: 0 7px;
      }
    }
    .app-logo img {
      max-width: 40px;
      width: 100%;
      @media (max-width: 320px) {
        max-width: 31px;
      }
    }
    .app-detail {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      text-align: left;
      line-height: 1.2;
      @media (max-width: 320px) {
        font-size: 14px;
      }
    }
  }
  .pull-right {
    float: right !important;
    .app-download {
      display: inline-block;
      vertical-align: middle;
      margin-top: 13%;
    }
    .app-download-button:link,
    .app-download-button:visited {
      border-radius: 2px;
      border: solid 1px #2898cb;
      padding: 5px 15px;
      margin: 0 auto;
      color: #2898cb;
    }
    .app-download-button:hover,
    .app-download-button:active {
      color: white;
      text-decoration: unset;
      background-color: #2898cb;
    }
    @media (max-width: 320px) {
      .app-download-button:link,
      .app-download-button:visited,
      .app-download-button:hover,
      .app-download-button:active {
        padding: 5px 10px;
      }
    }
  }
}
